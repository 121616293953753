@if (imageUrl && !imageLoaded) {
  <div class="size-[200px] bg-white flex justify-center items-center rounded">
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="50"
    ></mat-progress-spinner>
    <img [src]="imageUrl" class="hidden" (load)="onImageLoad()" />
  </div>
} @else {
  @if (imageUrl) {
    <div
      class="relative size-[200px] bg-white flex justify-center items-center asset-container rounded"
    >
      <div class="action-buttons absolute flex flex-col gap-2 items-center">
        <rk-button
          [variant]="'secondary'"
          (buttonClick)="crop.emit(assetKey)"
          [text]="'assets.crop-image' | translate"
          [fullWidth]="true"
        ></rk-button>
        <rk-button
          [variant]="'white'"
          (buttonClick)="replace.emit(assetKey)"
          [text]="'assets.change-image' | translate"
          [fullWidth]="true"
        ></rk-button>
      </div>
      <div class="relative flex justify-center items-center rounded">
        <!-- dynamic mocks -->
        @if (dynamicMocks.includes(assetKey)) {
          <rk-asset-dynamic-mock [assetMainColor]="assetMainColor" [assetKey]="assetKey">
          </rk-asset-dynamic-mock>
        } @else {
          <!-- static mocks -->
          @if (mockImage) {
            <img [src]="mockImage" [class]="mockImageClass" />
          }
        }
        <img
          [src]="imageUrl"
          [alt]="assetKey"
          [class]="imageClass || 'w-full h-full rounded object-cover'"
        />
      </div>
    </div>
  }
}
@if (!imageUrl) {
  <div>
    <rk-image-upload
      (fileSelected)="onRawImageSelected(assetKey, $event)"
      [deleteButton]="false"
      [width]="'200px'"
      [aspectRatio]="'1/1'"
      [compactLayout]="true"
    ></rk-image-upload>
  </div>
}
