<rk-stepper
  [customNextStepText]="'assets.next-step' | translate"
  [nextStepIsDisabled]="nextStepIsDisabled$ | async"
  [@fadeInOut]
></rk-stepper>
<div class="flex w-full justify-center flex-col items-center" [@fadeInOut]>
  @if (!isCreationProcess) {
    <div class="w-4/5">
      <!-- app name -->
      <div class="lex mt-13 relative">
        <div class="w-[500px] flex flex-col">
          <!-- name input -->
          <label class="font-bold text-2xl mb-8">{{
            'assets.app-name' | translate
          }}</label>
          <rk-app-name-input
            [appName]="appName.value"
            (appNameChange)="appName.setValue($event)"
            [greyBackground]="true"
            class="w-[600px]"
          ></rk-app-name-input>
        </div>
      </div>
      <!-- monetization -->
      <h1 class="text-2xl mt-13 font-bold w-4/5">
        {{ 'monetization.title' | translate }}
      </h1>
      <div class="w-full flex relative">
        <rk-admob></rk-admob>
      </div>
    </div>
  }
  @if (isCreationProcess) {
    <!-- almost finished message -->
    <div class="font-bold text-4xl text-center my-13">
      {{ 'assets.almost-finished' | translate }}
    </div>
  }
  <div class="w-4/5 min-w-[1000px]">
    <!-- upload assets (only in creation mode) -->
    @if (isCreationProcess) {
      <div class="flex">
        <!-- upload logo -->
        <div class="flex gap-5 w-1/2">
          <div>
            <rk-image-upload
              (fileSelected)="onRawImageSelected('rawLogo', $event)"
              [deleteButton]="false"
              [width]="'200px'"
              [aspectRatio]="'1/1'"
              [compactLayout]="true"
            ></rk-image-upload>
          </div>
          <div>
            <div class="font-bold text-lg mb-[10px]">
              {{ 'assets.app-logo' | translate }}
            </div>
            <div class="mb-4">{{ 'assets.app-logo-explained' | translate }}</div>
            <div class="text-sm">
              <div class="font-bold">
                {{ 'assets.restrictions' | translate }}
              </div>
              <div>
                {{ 'assets.app-logo-size' | translate }}
              </div>
            </div>
          </div>
        </div>
        <!-- upload splashscreen -->
        <div class="flex gap-5 flex-1 justify-start w-1/2">
          <div>
            <rk-image-upload
              (fileSelected)="onRawImageSelected('rawSplashscreen', $event)"
              [deleteButton]="false"
              [width]="'200px'"
              [aspectRatio]="'1/1'"
              [compactLayout]="true"
            ></rk-image-upload>
          </div>
          <div>
            <div class="font-bold text-lg mb-[10px]">
              {{ 'assets.splashscreens' | translate }}
            </div>
            <div class="mb-4">
              {{ 'assets.splashscreens-explained' | translate }}
            </div>
            <div class="text-sm">
              <div class="font-bold">
                {{ 'assets.restrictions' | translate }}
              </div>
              <div>
                {{ 'assets.splashscreens-size' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    @if (!isCreationProcess) {
      <h1 class="w-full text-2xl font-bold mt-5">
        {{ 'assets.update-title' | translate }}
      </h1>
    }
    <!-- display assets -->
    <div
      class="flex"
      [ngClass]="{
        'flex-col': (availableOnAndroidTV$ | async) && !isCreationProcess,
        'creation-layout': isCreationProcess,
        'gap-6 mt-8': !isCreationProcess,
        'mt-6': isCreationProcess
      }"
    >
      @if (logoURL$ | async) {
        <!-- logos -->
        <div>
          <div class="bg-[#f8f8f8] p-6 rounded asset-group w-fit relative">
            @if (isCreationProcess) {
              <div class="upper-arrow"></div>
            }
            @if (!isCreationProcess) {
              <div class="font-bold text-lg">
                {{ 'assets.app-logo' | translate }}
              </div>
              <div class="mb-4">{{ 'assets.app-logo-explained' | translate }}</div>
            }
            <div class="flex gap-4">
              <!-- Logo icon -->
              <div class="flex flex-col gap-5 w-[200px]">
                <rk-asset-display
                  [imageUrl]="logoURL$ | async"
                  assetKey="logo"
                  imageClass="absolute rounded size-[29px] top-[51px] left-[38px] object-cover"
                  (crop)="openCropper($event)"
                  (replace)="replaceAsset($event)"
                ></rk-asset-display>
                <div>
                  <div class="font-bold mb-1">
                    {{ 'assets.logo' | translate }}
                  </div>
                  <div class="opacity-70 text-sm">
                    {{ 'assets.logo-description' | translate }}
                  </div>
                </div>
              </div>
              <!-- Tv icon -->
              @if (availableOnAndroidTV$ | async) {
                <div class="flex flex-col gap-5 w-[200px]">
                  <rk-asset-display
                    [imageUrl]="iconTvURL$ | async"
                    assetKey="iconTv"
                    imageClass="absolute rounded-[3px] w-[33px] h-[18px] top-[82px] left-[52px] object-cover"
                    (crop)="openCropper($event)"
                    (replace)="replaceAsset($event)"
                  ></rk-asset-display>
                  <div>
                    <div class="font-bold mb-1">
                      {{ 'assets.icon-tv' | translate }}
                    </div>
                    <div class="opacity-70 text-sm">
                      {{ 'assets.icon-tv-description' | translate }}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }
      <!-- splashscreens -->
      @if (smartphoneURL$ | async) {
        <div class="right-column">
          <div class="bg-[#f8f8f8] p-6 rounded asset-group w-fit relative">
            @if (isCreationProcess) {
              <div class="upper-arrow"></div>
            }
            @if ((isCreationProcess$ | async) === false) {
              <div class="font-bold text-lg">
                {{ 'assets.splashscreens' | translate }}
              </div>
              <div class="mb-4">{{ 'assets.splashscreens-explained' | translate }}</div>
            }
            <div
              class="flex gap-4 asset-group w-fit shrink-0"
              [class.asset-grid]="isCreationProcess && (availableOnAndroidTV$ | async)"
            >
              <!-- splashscreen smartphone asset -->
              <div class="flex flex-col gap-5 w-[200px]">
                <rk-asset-display
                  [imageUrl]="smartphoneURL$ | async"
                  assetKey="splashscreen"
                  mockImage="/assets/splash-smartphone-mock.png"
                  mockImageClass="h-[149px] w-[86px]"
                  imageClass="absolute rounded-xl w-[82px] h-[145px] object-cover"
                  (crop)="openCropper($event)"
                  (replace)="replaceAsset($event)"
                ></rk-asset-display>
                <div>
                  <div class="font-bold mb-1">{{ 'assets.smartphone' | translate }}</div>
                  <div class="opacity-70 text-sm">
                    {{ 'assets.smartphone-description' | translate }}
                  </div>
                </div>
              </div>

              <!-- splashscreen large asset -->
              <div class="flex flex-col gap-5 w-[200px]">
                <rk-asset-display
                  [imageUrl]="smartphoneLargeURL$ | async"
                  assetKey="splashscreenLarge"
                  mockImage="/assets/splash-smartphonelarge-mock.png"
                  mockImageClass="h-[149px] w-[69px]"
                  imageClass="absolute rounded-[9px] w-[65px] h-[145px] object-cover"
                  (crop)="openCropper($event)"
                  (replace)="replaceAsset($event)"
                ></rk-asset-display>
                <div>
                  <div class="font-bold mb-1">
                    {{ 'assets.smartphone' | translate }} -
                    <span class="text-sm font-normal opacity-70">
                      {{ 'assets.large-screen' | translate }}</span
                    >
                  </div>
                  <div class="opacity-70 text-sm">
                    {{ 'assets.smartphone-long-description' | translate }}
                  </div>
                </div>
              </div>

              <!-- splashscreen TV asset -->
              @if (availableOnAndroidTV$ | async) {
                <div class="flex flex-col gap-5 w-[200px]">
                  <rk-asset-display
                    [imageUrl]="tvSplashscreenURL$ | async"
                    assetKey="splashscreenTv"
                    mockImage="/assets/splash-tv-mock.png"
                    mockImageClass="w-[150px] h-[102px]"
                    imageClass="absolute rounded-[2px] left-[2px] w-[147px] h-[92px] mb-[8px] object-cover"
                    (crop)="openCropper($event)"
                    (replace)="replaceAsset($event)"
                  ></rk-asset-display>
                  <div>
                    <div class="font-bold mb-1">{{ 'assets.tv' | translate }}</div>
                    <div class="opacity-70 text-sm">
                      {{ 'assets.tv-description' | translate }}
                    </div>
                  </div>
                </div>
              }
              <!-- splashscreen TV topshelf -->
              @if (availableOnAppleTV$ | async) {
                <div class="flex flex-col gap-5 w-[200px]">
                  <rk-asset-display
                    [imageUrl]="topShelfTvURL$ | async"
                    assetKey="topShelfTv"
                    imageClass="absolute rounded-[4px] w-[162px] h-[48px] object-cover left-[38px] top-[25px] "
                    (crop)="openCropper($event)"
                    (replace)="replaceAsset($event)"
                  ></rk-asset-display>
                  <div>
                    <div class="font-bold mb-1">
                      {{ 'assets.topshelf-tv-short' | translate }} -
                      <span class="text-sm font-normal opacity-70">
                        {{ 'assets.apple-only' | translate }}</span
                      >
                    </div>
                    <div class="opacity-70 text-sm">
                      {{ 'assets.topshelf-tv-description' | translate }}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
