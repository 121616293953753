import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, NgModel, ValidationErrors } from '@angular/forms';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { CheckAppNameAvailabilityRequest } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';

@Component({
  selector: 'rk-app-name-input',
  templateUrl: './app-name-input.component.html',
  styleUrls: ['./app-name-input.component.scss'],
})
export class AppNameInputComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input()
  appName: string;
  @Input()
  greyBackground = false;
  @Output()
  readonly appNameChange = new EventEmitter<string>();
  @Output()
  readonly errorCountChange = new EventEmitter<number>();
  @Output()
  readonly nameIsAvailable = new EventEmitter<boolean>();
  @Output()
  readonly nameHasNoErrors = new EventEmitter<boolean>();
  @ViewChild('appNameInput')
  appNameInput!: NgModel;
  @ViewChildren('errorDiv')
  errorDivs!: QueryList<ElementRef>;

  @Select(AppConfigurationState.typedAppNameIsAvailable)
  typedAppNameIsAvailable$: Observable<boolean>;

  @Select(AppConfigurationState.currentAppConfigName)
  currentAppConfigName$: Observable<string>;

  nameValidationQuery = new BehaviorSubject<string>('');
  private readonly subscriptions = new Subscription();

  constructor(private readonly store: Store) {}

  ngOnInit() {
    // checking if the name is available via API
    this.subscriptions.add(
      this.nameValidationQuery
        .pipe(skip(1), debounceTime(300), distinctUntilChanged())
        .subscribe(name => {
          this.store.dispatch(new CheckAppNameAvailabilityRequest(name));
        }),
    );

    // setting the name if existing app
    this.subscriptions.add(
      this.currentAppConfigName$.subscribe(currentAppConfigName => {
        if (currentAppConfigName) {
          this.appName = currentAppConfigName;
          this.appNameChange.emit(currentAppConfigName);
        }
      }),
    );

    // emit availability status
    this.subscriptions.add(
      this.typedAppNameIsAvailable$.subscribe(isAvailable => {
        this.nameIsAvailable.emit(isAvailable);
      }),
    );
  }

  ngAfterViewChecked() {
    this.emitErrorCount();
  }

  onNameInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.nameValidationQuery.next(value);
    this.appNameChange.emit(value);
    this.validateAppName();
  }

  // event tracking
  logEvent() {
    const isEmpty = this.appNameInput.control.value === '';
    const hasErrors = !!this.appNameInput.control.errors;

    this.store.dispatch(
      new SetEventProperties({
        name: 'InputAppName',
        component: 'Input',
        has_errors: hasErrors,
        is_empty: isEmpty,
      }),
    );

    this.store.dispatch(new LogEvent('Input Changed'));
  }

  validateAppName(): void {
    const control = this.appNameInput.control;
    const emojiError = this.noEmojiValidator(control);
    const spaceError = this.noSpaceOnlyValidator(control);
    const lengthError = this.lengthAfterTrimValidator(control);

    if (emojiError) {
      control.setErrors({ ...control.errors, ...emojiError });
    }

    if (spaceError) {
      control.setErrors({ ...control.errors, ...spaceError });
    }

    if (lengthError) {
      control.setErrors({ ...control.errors, ...lengthError });
    }

    // emit whether the name has no errors
    const hasErrors = !!control.errors;
    this.nameHasNoErrors.emit(!hasErrors);
  }

  // emitting error count to update parent component layout
  emitErrorCount(): void {
    const visibleErrorDivs = this.errorDivs.filter(div =>
      div.nativeElement.classList.contains('visible'),
    );
    this.errorCountChange.emit(visibleErrorDivs.length);
  }

  noEmojiValidator(control: AbstractControl): ValidationErrors | null {
    const emojiRegex = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/u;
    const containsEmoji = emojiRegex.test(control.value);

    return containsEmoji ? { noEmoji: true } : null;
  }

  noSpaceOnlyValidator(control: AbstractControl): ValidationErrors | null {
    return control.value?.trim().length === 0 && control.value?.length > 0
      ? { noSpaceOnly: true }
      : null;
  }

  lengthAfterTrimValidator(control: AbstractControl): ValidationErrors | null {
    const trimmedValue = control.value?.trim();

    return trimmedValue?.length > 1 ? null : { lengthAfterTrim: true };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
