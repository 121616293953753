import { Component } from '@angular/core';
import { fadeInOutAnimation, quickFadeInOutAnimation, slideInOutAnimation } from '@app/core/animations';
import { AuthState } from '@app/core/auth/states/auth.state';
import { AppState } from '@app/core/states/app.state';
import { ResponsiveState } from '@app/core/states/responsive.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OpenNotificationForm } from '../../states/notifications.actions';
import { NotificationsState } from '../../states/notifications.state';

@Component({
  selector: 'rk-notification-dashboard',
  templateUrl: './notification-dashboard.component.html',
  styleUrls: ['./notification-dashboard.component.scss'],
  animations: [fadeInOutAnimation, slideInOutAnimation, quickFadeInOutAnimation]
})
export class NotificationDashboardComponent {
  @Select(NotificationsState.isFormVisible)
  isFormVisible$: Observable<boolean>;
  @Select(NotificationsState.isOpenFormButtonVisible)
  isOpenFormButtonVisible$: Observable<boolean>;
  @Select(AppState.isLoading)
  isLoading$: Observable<boolean>;
  @Select(NotificationsState.totalNumber)
  totalNumber$: Observable<number>;
  @Select(AppState.hasNotificationsEnabled)
  hasNotificationsEnabled$: Observable<boolean>;
  @Select(ResponsiveState.showAppManagerMobileWarning)
  showAppManagerMobileWarning$: Observable<boolean>;
  @Select(AuthState.isAdminOrSuperuser)
  isAdminOrSuperuser$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  openForm() {
    this.store.dispatch(new OpenNotificationForm());
  }
}
