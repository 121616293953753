<header class="header-container w-full flex items-center justify-between relative">
  <div class="flex items-center">
    <div class="header-logo">
      <a routerLink="/"><img class="max-h-full" src="/assets/rk-logo.png" /></a>
    </div>
    
    <div *ngIf="showReturnToDashboardLink$ | async" class="cursor-pointer h-full">
      <div class="mx-3 return-to-dashboard-link h-full" (click)="onReturnToDashboard()">
        <a routerLink="/apps" >
          <fa-icon [icon]="'arrow-left'" class="ml-3 mr-2"></fa-icon
          >{{ 'header.return-to-dashboard-link' | translate }}</a
        >
      </div>
    </div>

    <div *ngIf="showAppList$ | async" class="dropdown">
      <rk-list></rk-list>
    </div>

  </div>
  <div
    *ngIf="currentAppIsNotOwnedByUser$ | async"
    class="text-white rounded bg-error text-xl px-3 py-1 flex items-center admin-message"
  >
    Ceci est l'application d'un client
    <span
      class="ml-2 px-2 py-1 cursor-pointer text-xs bg-white text-black rounded"
      (click)="resetCurrentApp()"
      >Retour</span
    >
  </div>
  <div *ngIf="userName$ | async" class="flex items-center">
    <button *ngIf="isLocal" (click)="switchLanguage()" class="mr-2">fr | en | de</button>
    <div class="flex items-center gap-2 mr-4">
      <a [href]="'header.help-link' | translate" target="_blank">
        <fa-icon
          [icon]="'question'"
          class="help-icon flex justify-center items-center"
        ></fa-icon>
      </a>
      <div>
        <fa-icon [icon]="'bell'" class="changelog-icon flex justify-center items-center">
        </fa-icon>
        <div class="headway-widget"></div>
      </div>
    </div>

    <rk-user-menu></rk-user-menu>
  </div>
</header>
