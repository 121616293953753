@if (showAppConfigMobileWarning$ | async) {
  <!-- mobile placeholder -->
  <rk-mobile-access-warning></rk-mobile-access-warning>
} @else {
  <!-- desktop screen-->
  <rk-stepper [@fadeInOut] [customNextStep]="postAppConfig"></rk-stepper>
  <div class="w-full app-configuration-container flex justify-between" [@fadeInOut]>
    <!-- left panel -->
    <div
      class="w-96 h-full bg-white overflow-hidden flex flex-col justify-between relative left-panel"
    >
      <rk-settings-category-list></rk-settings-category-list>
      <rk-add-new-page></rk-add-new-page>
    </div>
    <!-- simulator -->
    <div class="w-full h-full bg-stone-50 flex-1 flex justify-center items-center">
      <rk-simulator></rk-simulator>
    </div>
    <!-- right panel -->
    <div
      class="w-[448px] h-full bg-white overflow-scroll"
      [ngSwitch]="activeSettingsCategory$ | async"
    >
      <rk-general-configuration *ngSwitchCase="'general'"></rk-general-configuration>
      <rk-menu-configuration-shell *ngSwitchCase="'menu'"> </rk-menu-configuration-shell>
      <div *ngSwitchCase="'pages'" [ngSwitch]="activePageType$ | async">
        <rk-player-configuration-shell *ngSwitchCase="'radio'">
        </rk-player-configuration-shell>
        <rk-alarm-configuration *ngSwitchCase="'reveil'"></rk-alarm-configuration>
        <rk-audio-dedications-configuration
          *ngSwitchCase="'dedicacesaudio'"
        ></rk-audio-dedications-configuration>
        <rk-other-pages-configuration
          *ngSwitchCase="'rss'"
        ></rk-other-pages-configuration>
        <rk-other-pages-configuration
          *ngSwitchCase="'dedicaces'"
          [type]="'dedicaces'"
        ></rk-other-pages-configuration>
        <rk-other-pages-configuration
          *ngSwitchCase="'livefeed'"
          [type]="'livefeed'"
        ></rk-other-pages-configuration>
        <rk-other-pages-configuration
          *ngSwitchCase="'site'"
          [type]="'site'"
        ></rk-other-pages-configuration>
        <rk-html-page-configuration *ngSwitchCase="'html'"></rk-html-page-configuration>
      </div>
    </div>
  </div>
}
