import { Component, Input } from '@angular/core';

@Component({
  selector: 'rk-infobox',
  templateUrl: './infobox.component.html',
  styleUrl: './infobox.component.scss',
})
export class InfoboxComponent {
  @Input()
  type: 'info' | 'warning';

}
