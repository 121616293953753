import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { getMainColor } from '@app/core/utils';
import {
  PostImageAssetsRequest,
  SetRawImage,
  StoreAsset,
} from '@app/features/app-creation-and-configuration/states/assets.actions';
import { AssetKey } from '@app/features/app-creation-and-configuration/states/assets.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Store } from '@ngxs/store';

@Component({
  selector: 'rk-asset-display',
  templateUrl: './asset-display.component.html',
  styleUrl: './asset-display.component.scss',
})
export class AssetDisplayComponent implements OnChanges {
  @Input() imageUrl: string;
  @Input() assetKey: AssetKey;
  @Input() mockImage?: string;
  @Input() mockImageClass?: string;
  @Input() imageClass?: string;
  @Output() readonly crop = new EventEmitter<AssetKey>();
  @Output() readonly replace = new EventEmitter<string>();
  imageLoaded = false;
  previousImageUrl: string;
  assetMainColor: string;
  dynamicMocks: AssetKey[] = ['logo', 'iconTv', 'topShelfTv'];

  constructor(private readonly store: Store) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['imageUrl'] &&
      changes['imageUrl'].currentValue !== changes['imageUrl'].previousValue
    ) {
      this.imageLoaded = false;
      this.previousImageUrl = changes['imageUrl'].currentValue;
    }
  }

  async onImageLoad() {
    if (this.dynamicMocks.includes(this.assetKey)) {
      // background color logic
      try {
        this.assetMainColor = await getMainColor(this.imageUrl, 0.1);
        if (this.imageUrl === this.previousImageUrl) {
          this.imageLoaded = true;
        }
      } catch (error) {
        console.error('Error detecting main color:', error);
        if (this.imageUrl === this.previousImageUrl) {
          this.imageLoaded = true;
        }
      }
    } else {
      // no background color
      if (this.imageUrl === this.previousImageUrl) {
        this.imageLoaded = true;
      }
    }
  }

  onRawImageSelected(key: string, selectedFile: any) {
    const file = selectedFile.file;
    const imageUrl = URL.createObjectURL(file);
    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: `Upload${key}`,
        component: 'ImageUpload',
      }),
    );
    this.store.dispatch(new LogEvent('Image uploaded'));

    if (!file) {
      return;
    }
    this.setRawImage(key, file);
    this.storeAsset(key, file, imageUrl);
    this.postImageAssets();
  }

  @Dispatch()
  postImageAssets() {
    return new PostImageAssetsRequest();
  }

  @Dispatch()
  setRawImage(key: string, image: File) {
    return new SetRawImage(key, image);
  }

  @Dispatch()
  storeAsset(key: string, file: File, url: string) {
    return new StoreAsset(key, file, url);
  }
}
