<p class="mb-2 opacity-70">{{ 'general-configuration.body-size' | translate }}</p>
<div class="flex gap-4">
  <div
    class="flex flex-col rounded border margin-style-container flex-1"
    [class.margin-style-selected]="showMargins$ | async"
    (click)="onMarginStyleSelection(true)"
  >
    <div class="px-4 py-2 flex items-center">
      <div class="size-[15px] rounded-full border selector-circle"></div>
      <span class="ml-2">{{ 'margin-settings.with-margin' | translate }} </span>
    </div>
    <div class="flex justify-around items-center border-t margin-selector">
      <img class="max-h-full" src="/assets/with-margins-settings.svg" />
    </div>
  </div>
  <div
    class="flex flex-col rounded border margin-style-container flex-1"
    [class.margin-style-selected]="(showMargins$ | async) === false"
    (click)="onMarginStyleSelection(false)"
  >
    <div class="px-4 py-2 flex items-center">
      <div class="size-[15px] rounded-full border selector-circle"></div>
      <span class="ml-2">{{ 'margin-settings.no-margin' | translate }} </span>
    </div>
    <div class="flex justify-around items-center border-t margin-selector">
      <img class="max-h-full" src="/assets/no-margins-settings.svg" />
    </div>
  </div>
</div>
