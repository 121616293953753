<!-- keep same height and width in order to keep the same proportions inside the iframe -->
<!-- change scale in order to change size, not height nor width -->
<div class="w-[350px] h-[700px] relative simulator-container">
  <img src="/assets/phone-outline.png" />
  <div
    *ngIf="isIframeLoading"
    class="absolute inset-0 flex justify-center items-center z-10"
  >
    <mat-progress-spinner
      class="spinner"
      mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="50"
    ></mat-progress-spinner>
  </div>
  <iframe
    class="h-full w-full absolute top-0 left-0 rounded-[4rem]"
    [class.pointer-events-none]="showImageLoadingModal$ | async"
    #rnwIframe
    id="rnw-iframe"
    [src]="mobileAppSimulatorUrl"
  ></iframe>
  <!-- message preview -->
  <div
    [class.show-modal]="showImageLoadingModal$ | async"
    class="w-full h-full absolute top-0 left-0 rounded-[4rem] flex justify-center items-center simulator-overlay"
  >
    <div class="p-8 bg-white rounded w-3/4 text-center">
      <h1 class="text-xl font-bold mb-2">
        {{ 'simulator-preview.add-image-title' | translate }}
      </h1>
      <p>{{ 'simulator-preview.add-image-text' | translate }}</p>
    </div>
  </div>
</div>
