import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInOutAnimation } from '@app/core/animations';
import { AuthState } from '@app/core/auth/states/auth.state';
import { App } from '@app/core/models/app.model';
import { AppState } from '@app/core/states/app.state';
import { ResponsiveState } from '@app/core/states/responsive.state';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, distinctUntilChanged, filter, map } from 'rxjs';
import { PlatformSubscriber, SubscribersPerDay } from '../../models/statistics.models';
import {
  FetchConsumptionRequest,
  FetchNewSubscribersRequest,
  FetchNotificationsSentRequest,
  FetchSubscribersCountRequest,
  ResetStatisticsState,
} from '../../states/statistics.actions';
import { StatisticsState } from '../../states/statistics.state';

@Component({
  selector: 'rk-statistics-dashboard',
  templateUrl: './statistics-dashboard.component.html',
  styleUrls: ['./statistics-dashboard.component.scss'],
  animations: [fadeInOutAnimation],
})
export class StatisticsDashboardComponent implements OnInit, OnDestroy {
  @Select(AuthState.currentFirstname)
  currentFirstname$: Observable<string>;

  @Select(StatisticsState.subscriberCount)
  subscriberCount$: Observable<PlatformSubscriber[]>;

  @Select(StatisticsState.consumption)
  consumption$: Observable<{ consumption: number; limit: number; use_percent: number }>;

  @Select(StatisticsState.newSubscribers)
  newSubscribers$: Observable<SubscribersPerDay[]>;

  @Select(StatisticsState.notificationsSent)
  notificationsSent$: Observable<SubscribersPerDay[]>;

  @Select(AppState.isLoading)
  isLoading$: Observable<boolean>;

  @Select(AppState.currentAppId)
  currentAppId$: Observable<number>;

  @Select(AppState.currentApp)
  currentApp$: Observable<App>

  @Select(ResponsiveState.showAppManagerMobileWarning)
  showAppManagerMobileWarning$: Observable<boolean>;

  @Select(AuthState.isAdminOrSuperuser)
  isAdminOrSuperuser$: Observable<boolean>;

  isAdmin = false;


  private readonly subscription = new Subscription();
  shortUserName$: Observable<string>;
  totalSubscribers$: Observable<number>;
  iphonePercentage$: Observable<number>;
  androidPercentage$: Observable<number>;
  iphoneCount$: Observable<number>;
  androidCount$: Observable<number>;
  faApple = faApple;
  faAndroid = faAndroid;
  public currentLocale: string;

  constructor(
    private readonly store: Store,
    private readonly translateService: TranslateService,
  ) {
    this.totalSubscribers$ = this.subscriberCount$.pipe(
      map((subscriberCounts: PlatformSubscriber[]) => {
        if (subscriberCounts) {
          return subscriberCounts.reduce(
            (total, subscriberCount) => total + subscriberCount.count,
            0,
          );
        } else {
          return 0;
        }
      }),
    );

    this.iphonePercentage$ = this.subscriberCount$.pipe(
      map(subscriberCounts => {
        const iphoneDetail = subscriberCounts.find(
          detail => detail.platform === 'iphone',
        );
        const total = subscriberCounts.reduce((sum, detail) => sum + detail.count, 0);

        return iphoneDetail ? Math.round((iphoneDetail.count / total) * 100) : 0;
      }),
    );

    this.androidPercentage$ = this.subscriberCount$.pipe(
      map(subscriberCounts => {
        const androidDetail = subscriberCounts.find(
          detail => detail.platform === 'android',
        );
        const total = subscriberCounts.reduce((sum, detail) => sum + detail.count, 0);

        return androidDetail ? Math.round((androidDetail.count / total) * 100) : 0;
      }),
    );

    this.iphoneCount$ = this.subscriberCount$.pipe(
      map(subscriberCounts => {
        const iphoneDetail = subscriberCounts.find(
          detail => detail.platform === 'iphone',
        );

        return iphoneDetail ? iphoneDetail.count : 0;
      }),
    );

    this.androidCount$ = this.subscriberCount$.pipe(
      map(subscriberCounts => {
        const androidDetail = subscriberCounts.find(
          detail => detail.platform === 'android',
        );

        return androidDetail ? androidDetail.count : 0;
      }),
    );
  }
  ngOnInit() {

    this.subscription.add(
      this.isAdminOrSuperuser$.subscribe(isAdmin => (this.isAdmin = isAdmin)),
    );

    this.store.dispatch(new ResetStatisticsState());
    this.subscription.add(
      this.currentApp$
        .pipe(
          filter((app: App) => !!app?.appId && (app.androidNotificationIsAvailable || this.isAdmin)),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.store.dispatch(new FetchSubscribersCountRequest());
          this.store.dispatch(new FetchConsumptionRequest());
          this.store.dispatch(new FetchNewSubscribersRequest());
          this.store.dispatch(new FetchNotificationsSentRequest());
        })
    );

    this.currentLocale = this.translateService.currentLang;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
