import { DecimalPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import {
  PostImageAssetsRequest,
  SaveCropperState,
  SetRawImage,
  StoreAsset,
} from '@app/features/app-creation-and-configuration/states/assets.actions';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Store } from '@ngxs/store';
import Cropper from 'cropperjs';
import { UiModule } from '../../ui.module';

const titleMap = {
  splashscreen: marker('image-cropper.splashscreen'),
  splashscreenLarge: marker('image-cropper.splashscreenLarge'),
  splashscreenTv: marker('image-cropper.splashscreenTv'),
  topShelfTv: marker('image-cropper.topShelfTv'),
  iconTv: marker('image-cropper.iconTv'),
  logo: marker('image-cropper.logo'),
};

export interface CropperDialogData {
  sourceImageUrl: string;
  aspectRatio: number;
  width: number;
  height: number;
  key: keyof typeof titleMap;
  savedState?: CropperState;
}

export interface CropperState {
  x: number;
  y: number;
  width: number;
  height: number;
  rotate: number;
  scaleX: number;
  scaleY: number;
}

@Component({
  selector: 'rk-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrl: './image-cropper.component.scss',
  standalone: true,
  imports: [
    FontAwesomeModule,
    UiModule,
    TranslateModule,
    MatSliderModule,
    FormsModule,
    DecimalPipe,
    MatProgressSpinnerModule,
  ],
})
export class ImageCropperComponent implements OnInit, OnDestroy {
  @ViewChild('image') imageElement: ElementRef<HTMLImageElement>;
  private cropper: Cropper;
  imageUrl: string;
  rotation = 0;
  zoom = 0;
  isLoading = true;
  readonly minZoom = -1;
  readonly maxZoom = 1;
  private initialZoom: number;

  titleMap = titleMap;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    public store: Store,
    @Inject(MAT_DIALOG_DATA) public data: CropperDialogData,
  ) {
    this.imageUrl = data.sourceImageUrl;
    if (data.savedState) {
      this.rotation = data.savedState.rotate;
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.initCropper();
    }, 100);
  }

  private initCropper() {
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      aspectRatio: this.data.aspectRatio,
      viewMode: 0,
      dragMode: 'move',
      autoCropArea: 1,
      cropBoxMovable: true,
      cropBoxResizable: true,
      background: true,
      modal: true,
      restore: false,
      zoomable: true,
      zoomOnTouch: false,
      zoomOnWheel: false,
      ready: () => {
        // store the initial zoom level
        const imageData = this.cropper.getImageData();
        const canvasData = this.cropper.getCanvasData();
        this.initialZoom = canvasData.width / imageData.naturalWidth;
        // restore saved state if available
        if (this.data.savedState) {
          this.cropper.setData({
            x: this.data.savedState.x,
            y: this.data.savedState.y,
            width: this.data.savedState.width,
            height: this.data.savedState.height,
            rotate: this.data.savedState.rotate,
            scaleX: this.data.savedState.scaleX,
            scaleY: this.data.savedState.scaleY,
          });
        }
        this.isLoading = false;
      },
    });
  }

  async onSave() {
    if (!this.cropper) {
      return;
    }

    try {
      // save cropper state
      const cropperData = this.cropper.getData(true);
      const cropperState: CropperState = {
        ...cropperData,
      };
      this.store.dispatch(new SaveCropperState(this.data.key, cropperState));

      const canvas = this.cropper.getCroppedCanvas({
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: '#FFFFFF',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      });

      const blob = await new Promise<Blob>(resolve => {
        canvas.toBlob(croppedBlob => resolve(croppedBlob), 'image/png', 1.0);
      });

      this.dialogRef.close(blob);
    } catch (error) {
      console.error('Error saving cropped image:', error);
      this.dialogRef.close();
    }
  }
  rotate(degree: number) {
    this.cropper?.rotateTo(degree);
  }

  setZoom(relativeZoom: number) {
    const actualZoom = this.initialZoom * (1 + relativeZoom);
    this.cropper?.zoomTo(actualZoom);
  }

  onCancel() {
    this.dialogRef.close();
  }

  async onReplace() {
    const key = this.data.key;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png,.jpg,.jpeg';

    input.onchange = async (event: Event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];
      const imageUrl = URL.createObjectURL(file);
      if (!file) {
        return;
      }
      this.storeAsset(key, file, imageUrl);
      this.setRawImage(key, file);
      this.saveCropperState(this.data.key, null);
      this.postImageAssets();
      this.dialogRef.close();
    };

    input.click();
  }

  ngOnDestroy() {
    if (this.cropper) {
      this.cropper.destroy();
    }
    URL.revokeObjectURL(this.imageUrl);
  }

  @Dispatch()
  storeAsset(key: string, file: File, url: string) {
    return new StoreAsset(key, file, url);
  }

  @Dispatch()
  postImageAssets() {
    return new PostImageAssetsRequest();
  }

  @Dispatch()
  setRawImage(key: string, image: File) {
    return new SetRawImage(key, image);
  }

  @Dispatch()
  saveCropperState(key: string, data: CropperState) {
    return new SaveCropperState(key, data);
  }
}
