import { Injectable } from '@angular/core';
import { CropperState } from '@app/ui/components/image-cropper/image-cropper.component';
import { environment } from '@env/environment';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigurationService } from '../services/app-configuration.service';
import { SetApiErrorMessage } from './app-configuration.actions';
import { AppConfigurationState } from './app-configuration.state';
import {
  FetchAssetsError,
  FetchAssetsRequest,
  FetchAssetsSuccess,
  PostImageAssetsError,
  PostImageAssetsRequest,
  PostImageAssetsSuccess,
  SaveCropperState,
  SetRawImage,
  StoreAppName,
  StoreAsset,
  UpdateAssetsError,
  UpdateAssetsRequest,
  UpdateAssetsSuccess,
} from './assets.actions';
import { MonetizationState } from './monetizations.state';

export type AssetKey =
  | 'splashscreen'
  | 'splashscreenLarge'
  | 'splashscreenTv'
  | 'topShelfTv'
  | 'iconTv'
  | 'logo';

export type RawImages = Record<AssetKey, File>;

export type FetchedAssets = Record<AssetKey, string>;

export type StoredAssets = Record<AssetKey, { url: string; file: File }>;

export interface AssetsStateModel {
  // raw source images added by the user during session
  rawImages: RawImages;
  // cropped assets stored waiting to be sent to the server
  storedAssets: StoredAssets;
  // cropped assets received from the server
  fetchedAssets: FetchedAssets;
  cropperStates: Record<AssetKey, CropperState>;
  appName: string;
  isLoading: boolean;
}

@State<AssetsStateModel>({
  name: 'assets',
  defaults: {
    rawImages: null,
    storedAssets: null,
    fetchedAssets: null,
    cropperStates: null,
    appName: null,
    isLoading: false,
  },
})
@Injectable()
export class AssetsState {
  constructor(
    private readonly appConfigurationService: AppConfigurationService,
    private readonly store: Store,
  ) {}

  @Selector()
  static rawImages(state: AssetsStateModel): { [key: string]: File } {
    return state.rawImages;
  }

  @Selector()
  static fetchedAssets(state: AssetsStateModel): { [key: string]: string } {
    return state.fetchedAssets;
  }

  @Selector()
  static smartphoneSplashscreenURL(state: AssetsStateModel): string {
    return (
      state.storedAssets?.['splashscreen']?.url ??
      constructAssetUrl(state.fetchedAssets['splashscreen'])
    );
  }

  @Selector()
  static smartphoneLargeSplashscreenURL(state: AssetsStateModel): string {
    return (
      state.storedAssets?.['splashscreenLarge']?.url ??
      constructAssetUrl(state.fetchedAssets['splashscreenLarge'])
    );
  }

  @Selector()
  static tvSplashscreenURL(state: AssetsStateModel): string {
    return (
      state.storedAssets?.['splashscreenTv']?.url ??
      constructAssetUrl(state.fetchedAssets['splashscreenTv'])
    );
  }

  @Selector()
  static logoURL(state: AssetsStateModel): string {
    return (
      state.storedAssets?.['logo']?.url ?? constructAssetUrl(state.fetchedAssets['logo'])
    );
  }

  @Selector()
  static iconTvURL(state: AssetsStateModel): string {
    return (
      state.storedAssets?.['iconTv']?.url ??
      constructAssetUrl(state.fetchedAssets['iconTv'])
    );
  }

  @Selector()
  static topShelfTvURL(state: AssetsStateModel): string {
    return (
      state.storedAssets?.['topShelfTv']?.url ??
      constructAssetUrl(state.fetchedAssets['topShelfTv'])
    );
  }

  @Selector()
  static isLoading(state: AssetsStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static cropperStates(state: AssetsStateModel) {
    return state.cropperStates;
  }

  @Action(StoreAsset)
  AddAsset(ctx: StateContext<AssetsStateModel>, { name, file, url }: StoreAsset) {
    const state = ctx.getState();
    const updatedAssets = { ...state.storedAssets, [name]: { file, url } };
    ctx.patchState({
      storedAssets: updatedAssets,
    });
  }

  @Action(PostImageAssetsRequest)
  postImageAssetsRequest(ctx: StateContext<AssetsStateModel>) {
    const assets = ctx.getState().storedAssets;
    ctx.patchState({
      isLoading: true,
    });

    const currentAppId = this.store.selectSnapshot(
      state => state.appConfiguration.currentAppConfig.idapplications,
    );

    return this.appConfigurationService.postAssetImage(currentAppId, assets).pipe(
      tap(() => {
        ctx.dispatch(new PostImageAssetsSuccess());
        ctx.dispatch(new FetchAssetsRequest(currentAppId));
      }),
      catchError(error => ctx.dispatch(new PostImageAssetsError(error))),
    );
  }

  @Action(PostImageAssetsSuccess)
  postImageAssetsSuccess(ctx: StateContext<AssetsStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(PostImageAssetsError)
  postImageAssetsError(
    ctx: StateContext<AssetsStateModel>,
    { error }: PostImageAssetsError,
  ) {
    ctx.patchState({
      isLoading: false,
    });
    console.error('Error posting assets image:', error);
  }

  @Action(FetchAssetsRequest)
  fetchAssetsRequest(ctx: StateContext<AssetsStateModel>, { appId }: FetchAssetsRequest) {
    ctx.patchState({
      isLoading: true,
    });

    return this.appConfigurationService.getAppConfig(appId).pipe(
      tap(config => {
        ctx.dispatch(new FetchAssetsSuccess(config));
      }),
      catchError(error => ctx.dispatch(new FetchAssetsError(error))),
    );
  }

  @Action(FetchAssetsSuccess)
  fetchAssetsSuccess(
    ctx: StateContext<AssetsStateModel>,
    { currentAppConfig }: FetchAssetsSuccess,
  ) {
    const { splashscreen, splashscreenLarge, splashscreenTv, topShelfTv, iconTv, logo } =
      currentAppConfig;

    const fetchedAssets = {
      splashscreen,
      splashscreenLarge,
      splashscreenTv,
      topShelfTv,
      iconTv,
      logo,
    };
    ctx.patchState({ isLoading: false, fetchedAssets });
  }

  @Action(FetchAssetsError)
  fetchAssetsError(ctx: StateContext<AssetsStateModel>, { error }: FetchAssetsError) {
    ctx.patchState({
      isLoading: false,
    });
    console.error('Error fetching assets:', error);
  }

  @Action(SetRawImage)
  setRawImage(ctx: StateContext<AssetsStateModel>, { key, image }: SetRawImage) {
    const state = ctx.getState();
    ctx.patchState({
      rawImages: {
        ...state.rawImages,
        [key]: image,
      },
    });
  }

  @Action(StoreAppName)
  storeAppName(ctx: StateContext<AssetsStateModel>, { name }: StoreAppName) {
    ctx.patchState({
      appName: name,
    });
  }

  @Action(UpdateAssetsRequest)
  updateAssetsRequest(ctx: StateContext<AssetsStateModel>) {
    ctx.patchState({
      isLoading: true,
    });

    const currentAppId = this.store.selectSnapshot(
      AppConfigurationState.currentAppConfigId,
    );

    const monetizationSettings = this.store.selectSnapshot(
      MonetizationState.storedMonetizationSettings,
    );

    const newName = ctx.getState().appName;

    return this.appConfigurationService
      .updateAssets(currentAppId, monetizationSettings, newName)
      .pipe(
        tap(() => {
          ctx.dispatch(new UpdateAssetsSuccess());
        }),
        catchError(error => ctx.dispatch(new UpdateAssetsError(error))),
      );
  }

  @Action(UpdateAssetsSuccess)
  updateAssetsSuccess(ctx: StateContext<AssetsStateModel>) {
    const currentAppId = this.store.selectSnapshot(
      AppConfigurationState.currentAppConfigId,
    );
    ctx.patchState({
      isLoading: false,
    });
    window.location.href = `${environment.urls.MOBILE_API}/publication/app/${currentAppId}`;
  }

  @Action(UpdateAssetsError)
  updateAssetsError(ctx: StateContext<AssetsStateModel>, { error }: UpdateAssetsError) {
    ctx.patchState({
      isLoading: false,
    });

    this.store.dispatch(new SetApiErrorMessage(error.error.error));

  }

  @Action(SaveCropperState)
  saveCropperState(
    ctx: StateContext<AssetsStateModel>,
    { key, state }: SaveCropperState,
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      cropperStates: {
        ...currentState.cropperStates,
        [key]: state,
      },
    });
  }
}

export function constructAssetUrl(partialUrl: string): string {
  return partialUrl
    ? `https://www.${environment.urls.RADIOKING_DOMAIN}/upload/applications/${partialUrl}`
    : null;
}
