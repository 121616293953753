<rk-page-configuration-container>
  <div content>
    @if (userHasNoRkRadio) {
      <div class="px-6 pb-6">
        <div class="bg-brand-light text-brand text-sm p-4 rounded">
          {{ 'audio-dedications.need-a-rk-radio' | translate }}
          <a
            class="font-semibold"
            [href]="createRadioMarketingLink$ | async"
            target="_blank"
          >
            {{ 'audio-dedications.rk-create-radio-cta' | translate }}
          </a>
        </div>
      </div>
    }
    <div [class.opacity-50]="userHasNoRkRadio">
      <rk-setting-divider></rk-setting-divider>
      <div class="p-6">
        <div class="font-bold mb-4">{{ 'audio-dedications.radio' | translate }}</div>
        <rk-dropdown
          [options]="userRadioOptions"
          [selectedOption]="currentUserRadio"
          [fullWidth]="true"
          (selectionChange)="onUserRadioChange($event)"
          [placeholder]="'audio-dedications.choose-radio' | translate"
        >
        </rk-dropdown>
      </div>
      <rk-setting-divider></rk-setting-divider>
      <div class="p-6">
        <div class="font-bold mb-4">{{ 'audio-dedications.text' | translate }}</div>
        <rk-form-field>
          <div class="flex justify-between items-center">
            <span class="opacity-70">
              {{ 'audio-dedications.welcome' | translate }}
            </span>
            <rk-switch
              [isChecked]="welcomeMessageIsActive"
              (toggle)="onWelcomeMessageIsActiveChange($event)"
              [isDisabled]="userHasNoRkRadio"
            ></rk-switch>
          </div>
          <textarea
            *ngIf="welcomeMessage && welcomeMessageIsActive"
            [formControl]="welcomeMessage"
            maxlength="100"
            id="welcomeTextarea"
            class="growable-textarea mt-2"
            placeholder="{{ 'audio-dedications.welcome-placeholder' | translate }}"
            (input)="adjustTextareaHeight($event)"
          ></textarea>
        </rk-form-field>
        <rk-form-field>
          <div class="flex justify-between items-center mt-3">
            <span class="opacity-70"> {{ 'audio-dedications.end' | translate }} </span>
            <rk-switch
              [isChecked]="endMessageIsActive"
              (toggle)="onEndMessageIsActiveChange($event)"
              [isDisabled]="userHasNoRkRadio"
            ></rk-switch>
          </div>
          <textarea
            *ngIf="endMessage && endMessageIsActive"
            [formControl]="endMessage"
            maxlength="140"
            id="endTextarea"
            class="growable-textarea mt-2"
            placeholder="{{ 'audio-dedications.end-placeholder' | translate }}"
            (input)="adjustTextareaHeight($event)"
          ></textarea>
        </rk-form-field>
      </div>
    </div>
  </div>
</rk-page-configuration-container>
