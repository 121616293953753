import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subscription } from 'rxjs';

export interface Selectedimage {
  file: File;
  imageUrl: SafeUrl;
}

@Component({
  selector: 'rk-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  icon?: IconProp;
  @Input()
  compactLayout?: boolean;
  @Input()
  text?: string;
  @Input()
  radius = 50;
  @Input()
  strokeWidth = 10;
  @Input()
  linkedWord?: string;
  @Input()
  draggingOverText?: string;
  @Input()
  hideCancelButton?: boolean;
  @Input()
  cancelButtonText?: string;
  @Input()
  fetchedImageUrl: string | SafeUrl;
  @Input()
  mockUploadTime = 1500;
  @Input()
  technicalRequirementsText?: string;
  @Input()
  mockImageUrl?: string;
  @Input()
  iconSize? = 50;
  @Input()
  title: string = null;
  @Input()
  description: string;
  @Input()
  deleteButton = true;
  @Input()
  aspectRatio?: string;
  @Input()
  width: string;
  @Input()
  horizontal?: boolean;
  @Input()
  containerAspectRatio: string;
  @Output()
  readonly fileSelected = new EventEmitter<Selectedimage>();
  @Output()
  readonly uploadCanceled = new EventEmitter();
  @Output()
  readonly imageRemoved = new EventEmitter();

  private readonly subscription = new Subscription();

  file: File;
  uploadProgress = 0;
  imageUrl: SafeUrl;
  accepts = '.png,.jpg,.jpeg';
  draggingOver = false;
  private mockUploadTask: any;
  private delayImageDisplay: any;
  MAX_FILE_SIZE = 10485760;
  errorMessage: string = null;

  ngOnInit(): void {
    if (this.fetchedImageUrl) {
      this.imageUrl = this.fetchedImageUrl;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fetchedImageUrl']) {
      this.imageUrl = this.fetchedImageUrl;
    }
  }

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.draggingOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.draggingOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.draggingOver = false;
    const files = evt.dataTransfer?.files;
    if (files && files.length > 0) {
      const file = files[0];
      this.onFileSelected(file);
    }
  }

  @ViewChild('fileInput')
  fileInput!: ElementRef;

  openFileInput(event: MouseEvent): void {
    event.stopPropagation();
    this.fileInput.nativeElement.click();
  }

  onFileSelected(file: File) {
    this.draggingOver = false;
    this.resetFile();

    if (!this.isValidFileType(file)) {
      this.displayErrorMessage(marker('image-upload.file-type-error'));

      return;
    }

    if (file.size > this.MAX_FILE_SIZE) {
      this.displayErrorMessage(marker('image-upload.file-size-error'));

      return;
    }

    this.file = file;
    // mock upload
    const updateInterval = 10;
    let currentProgress = 0;
    this.mockUploadTask = setInterval(() => {
      const progressIncrement = (updateInterval / this.mockUploadTime) * 100;
      currentProgress += progressIncrement;
      const roundedProgress = Math.round(currentProgress);

      if (roundedProgress >= 100) {
        currentProgress = 100;
        this.updateProgress(100);
        clearInterval(this.mockUploadTask);
      } else {
        this.updateProgress(roundedProgress);
      }
    }, updateInterval);
    // delay image display
    this.delayImageDisplay = setTimeout(() => {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.fileSelected.emit({ file, imageUrl: this.imageUrl });
      };
      reader.readAsDataURL(file);
    }, this.mockUploadTime);
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      this.onFileSelected(file);
      this.fileInput.nativeElement.value = '';
    }
  }

  updateProgress(progress: number) {
    this.uploadProgress = progress;
  }

  cancelUpload() {
    this.resetFile();
    clearInterval(this.mockUploadTask);
    clearTimeout(this.delayImageDisplay);
    this.uploadCanceled.emit();
  }

  removeImage() {
    this.resetFile();
    this.imageRemoved.emit();
  }

  public resetFile() {
    this.uploadProgress = 0;
    this.file = null;
    this.imageUrl = null;
  }

  private isValidFileType(file: File): boolean {
    const acceptedTypes = this.accepts.split(',').map(type => type.trim());

    return (
      acceptedTypes.includes(file.type) ||
      acceptedTypes.some(type => file.name.endsWith(type))
    );
  }

  private displayErrorMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => {
      this.errorMessage = null;
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.mockUploadTask) {
      clearInterval(this.mockUploadTask);
    }
    if (this.delayImageDisplay) {
      clearTimeout(this.delayImageDisplay);
    }
    this.subscription.unsubscribe();
  }
}
