import { Injectable } from '@angular/core';
import { FetchAppsError } from '@app/core/states/app.actions';
import { FetchAdminAppListsError } from '@app/features/admin/states/admin.actions';
import { PostAppConfigSuccess } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { PostImageAssetsError, PostImageAssetsSuccess } from '@app/features/app-creation-and-configuration/states/assets.actions';
import {
  FetchConsumptionError,
  FetchNewSubscribersError,
  FetchNotificationsSentError,
  FetchSubscribersCountError,
} from '@app/features/statistics/states/statistics.actions';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { mergeMap, of } from 'rxjs';
import { LoginError } from '../../core/auth/states/auth.actions';
import {
  DeleteNotificationSuccess,
  FetchNotificationsError,
  SubmitDraftSuccess,
  SubmitNotificationError,
  SubmitNotificationFrequencyError,
  SubmitNotificationQuotaError,
} from '../../features/notifications/states/notifications.actions';
import { AlertVariants } from '../components/alert/alert.model';
import { AlertService } from '../components/alert/alert.service';

interface AlertMessage {
  action: any[];
  key: string;
  variant: AlertVariants;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalAlertService {
  private readonly globalAlerts: AlertMessage[] = [
    {
      action: [DeleteNotificationSuccess],
      key: marker('toast.notification-deleted'),
      variant: 'success',
    },
    {
      action: [SubmitDraftSuccess],
      key: marker('toast.notification-draft'),
      variant: 'success',
    },
    {
      action: [SubmitNotificationError],
      key: marker('toast.generic-error'),
      variant: 'danger',
    },
    {
      action: [SubmitNotificationFrequencyError],
      key: marker('toast.notification-frequency-error'),
      variant: 'danger',
    },
    {
      action: [SubmitNotificationQuotaError],
      key: marker('toast.notification-quota-error'),
      variant: 'danger',
    },
    {
      action: [
        FetchAppsError,
        FetchAdminAppListsError,
        FetchNotificationsError,
        FetchConsumptionError,
        FetchNewSubscribersError,
        FetchNotificationsSentError,
        FetchSubscribersCountError,
        PostImageAssetsError
      ],
      key: marker('toast.generic-fetch-error'),
      variant: 'danger',
    },
    { action: [LoginError], key: marker('toast.login_error'), variant: 'danger' },
    {
      action: [PostAppConfigSuccess, PostImageAssetsSuccess],
      key: marker('toast.post-config-success'),
      variant: 'success',
    },
  ];

  constructor(
    private readonly alertService: AlertService,
    private readonly actions$: Actions,
    private readonly translate: TranslateService,
  ) {}
  public init() {
    const actions = this.globalAlerts.flatMap(message => message.action);
    this.actions$
      .pipe(
        ofActionDispatched(...actions),
        mergeMap(action => {
          const message = this.globalAlerts.find(m =>
            m.action.includes(action.constructor),
          );
          if (message) {
            return this.translate
              .get(message.key)
              .pipe(
                mergeMap(translation => of({ translation, variant: message.variant })),
              );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(({ translation, variant }) => {
        if (translation && variant) {
          this.alertService.addAlert({
            variant,
            title: null,
            description: translation,
            close: true,
            cta: null,
            autoHide: 4000,
          });
        }
      });
  }
}
