import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigurationService } from '../services/app-configuration.service';
import {
  FetchMonetizationError,
  FetchMonetizationRequest,
  FetchMonetizationSuccess,
  StoreMonetizationSetting,
} from './monetization.actions';

export type MonetizationSettingKey =
  | 'admobAppId'
  | 'admobInterstitialId'
  | 'admobBannerId'
  | 'admobAppIdIos'
  | 'admobInterstitialIdIos'
  | 'admobBannerIdIos'
  | 'pubOption';

export type MonetizationSettings = Record<MonetizationSettingKey, string>;

export interface MonetizationStateModel {
  fetchedMonetizationSettings: MonetizationSettings;
  storedMonetizationSettings: MonetizationSettings;
  isLoading: boolean;
}

@State<MonetizationStateModel>({
  name: 'monetizations',
  defaults: {
    // settings fetched from the server
    fetchedMonetizationSettings: null,
    // settings stored waiting to be sent to the server
    storedMonetizationSettings: null,
    isLoading: false,
  },
})
@Injectable()
export class MonetizationState {
  constructor(
    private readonly appConfigurationService: AppConfigurationService,
    private readonly store: Store,
  ) {}

  @Selector()
  static fetchedMonetizationSettings(
    state: MonetizationStateModel,
  ): MonetizationSettings {
    return state.fetchedMonetizationSettings;
  }

  @Selector()
  static storedMonetizationSettings(state: MonetizationStateModel): MonetizationSettings {
    return state.storedMonetizationSettings;
  }

  @Selector()
  static isLoading(state: MonetizationStateModel): boolean {
    return state.isLoading;
  }

  @Action(FetchMonetizationRequest)
  fetchMonetizationRequest(
    ctx: StateContext<MonetizationStateModel>,
    { appId }: FetchMonetizationRequest,
  ) {
    ctx.patchState({
      isLoading: true,
    });

    return this.appConfigurationService.getAppConfig(appId).pipe(
      tap(settings => {
        ctx.dispatch(new FetchMonetizationSuccess(settings));
      }),
      catchError(error => ctx.dispatch(new FetchMonetizationError(error))),
    );
  }

  @Action(FetchMonetizationSuccess)
  fetchMonetizationSuccess(
    ctx: StateContext<MonetizationStateModel>,
    { currentAppConfig }: FetchMonetizationSuccess,
  ) {
    const {
      admobAppId,
      admobInterstitialId,
      admobBannerId,
      admobAppIdIos,
      admobInterstitialIdIos,
      admobBannerIdIos,
      pubOption,
    } = currentAppConfig;

    const monetizationSettings = {
      admobAppId,
      admobInterstitialId,
      admobBannerId,
      admobAppIdIos,
      admobInterstitialIdIos,
      admobBannerIdIos,
      pubOption,
    };

    ctx.patchState({
      isLoading: false,
      fetchedMonetizationSettings: monetizationSettings,
    });
  }

  @Action(FetchMonetizationError)
  fetchMonetizationError(
    ctx: StateContext<MonetizationStateModel>,
    { error }: FetchMonetizationError,
  ) {
    ctx.patchState({
      isLoading: false,
    });
    console.error('Error fetching monetization settings:', error);
  }

  @Action(StoreMonetizationSetting)
  storeMonetizationSetting(
    ctx: StateContext<MonetizationStateModel>,
    { key, value }: StoreMonetizationSetting,
  ) {
    const state = ctx.getState();
    const updatedSettings = { ...state.storedMonetizationSettings, [key]: value };
    ctx.patchState({
      storedMonetizationSettings: updatedSettings,
    });
  }
}
