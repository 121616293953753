import { AppConfigApi } from '../models/app-config.model';

const tag = '[Assets]';

export class StoreAsset {
  static readonly type = `${tag} add asset`;
  constructor(
    public name: string,
    public file: File,
    public url: string,
  ) {}
}

export class FetchAssetsRequest {
  static readonly type = `${tag} fetch assets request`;
  constructor(public appId: number) {}
}

export class FetchAssetsSuccess {
  static readonly type = `${tag} fetch assets success`;
  constructor(public currentAppConfig: AppConfigApi) {}
}

export class FetchAssetsError {
  static readonly type = `${tag} fetch assets error`;
  constructor(public error: any) {}
}

export class PostImageAssetsRequest {
  static readonly type = `${tag} post image assets request`;
}

export class PostImageAssetsSuccess {
  static readonly type = `${tag} post image assets success`;
}

export class PostImageAssetsError {
  static readonly type = `${tag} post image assets error`;
  constructor(public error: any) {}
}

export class SetRawImage {
  static readonly type = `${tag} set raw image`;
  constructor(
    public key: string,
    public image: File,
  ) {}
}


export class StoreAppName {
  static readonly type = `${tag} store app name`;
  constructor(
    public name: string,
  ) {}
}

export class UpdateAssetsRequest {
  static readonly type = `${tag} update assets request`;
}

export class UpdateAssetsSuccess {
  static readonly type = `${tag} update assets success`;
}

export class UpdateAssetsError {
  static readonly type = `${tag} update assets error`;
  constructor(public error: any) {}
}
export class SaveCropperState {
  static readonly type = `${tag} save cropper state`;
  constructor(
    public key: string,
    public state: any,
  ) {}
}
