import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { UnsavedChangesService } from '../../services/unsaved-changes.service';
import { PostAppConfigRequest } from '../../states/app-configuration.actions';
import { AppConfigurationState } from '../../states/app-configuration.state';
import { NextStep, PreviousStep } from '../../states/stepper.actions';
import { Step, StepperState } from '../../states/stepper.state';
import { AssetsState } from '../../states/assets.state';
import { UpdateAssetsRequest } from '../../states/assets.actions';

@Component({
  selector: 'rk-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnDestroy {
  @Input()
  customNextStep?: () => void;

  @Input()
  nextStepIsDisabled?: boolean;

  @Input()
  hidePreviousStep?: boolean;

  @Input()
  customNextStepText?: string;

  @Select(StepperState.steps)
  steps$: Observable<Step[]>;

  @Select(StepperState.stepsLength)
  stepsLength$: Observable<number>;

  @Select(StepperState.isLastStep)
  isLastStep$: Observable<boolean>;

  @Select(StepperState.isCreationProcess)
  isCreationProcess$: Observable<boolean>;

  @Select(AppConfigurationState.appConfigIsLoaded)
  appConfigIsLoaded$: Observable<boolean>;

  @Select(StepperState.currentStep)
  currentStep$: Observable<number>;

  @Select(StepperState.tooltipMessage)
  tooltipMessage$: Observable<string>;

  @Select(AppConfigurationState.isLoading)
  isLoading$: Observable<boolean>;

  @Select(AssetsState.isLoading)
  isLoadingAssets$: Observable<boolean>;

  currentStep: number;
  tooltipFitContentStyle = { width: 'max-content', 'min-width': 'auto' };

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly unsavedChangesService: UnsavedChangesService,
    private readonly store: Store,
  ) {}

  @Dispatch()
  nextStep() {
    return new NextStep();
  }

  @Dispatch()
  previousStep() {
    return new PreviousStep();
  }

  @Dispatch()
  saveConfig() {
    return new PostAppConfigRequest();
  }

  @Dispatch()
  updateAssets() {
    return new UpdateAssetsRequest();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.currentStep$.subscribe(step => (this.currentStep = step)),
    );
  }

  next() {
    if (this.customNextStep) {
      this.customNextStep();
    } else {
      this.nextStep();
    }

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'StepperNext',
        component: 'Stepper',
        current_step: this.currentStep,
      }),
    );
    this.store.dispatch(new LogEvent('Stepper Clicked'));
  }

  previous() {
    this.subscriptions.add(
      this.unsavedChangesService.confirmDiscardChanges().subscribe(canNavigate => {
        if (canNavigate) {
          this.previousStep();
        }
      }),
    );

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'StepperPrevious',
        component: 'Stepper',
      }),
    );
    this.store.dispatch(new LogEvent('Stepper Clicked'));
  }

  save() {
    if (this.currentStep === 1) {
      this.saveConfig();
    } else if (this.currentStep === 2) {
      this.updateAssets();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
