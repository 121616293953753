import { AppConfigTabs } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { AppConfigApi } from '../models/app-config.model';
import { TabModel } from '../models/app-tabs.model';
import { UserRadio } from './app-configuration.state';

const tag = '[AppConfiguration]';

export class FetchAppNameAndLogoRequest {
  static readonly type = `${tag} fetch app name and logo request`;
  constructor(public appId: number) {}
}

export class FetchAppNameAndLogoSuccess {
  static readonly type = `${tag} fetch app name and logo success`;
  constructor(public apiResponse: Partial<AppConfigApi>) {}
}

export class FetchAppNameAndLogoError {
  static readonly type = `${tag} fetch app name and logo error`;
  constructor(public error: any) {}
}

export class PostNameAndLogo {
  static readonly type = `${tag} post name and logo`;
  constructor(
    public name: string,
    public logo?: Blob,
    public appId?: number,
  ) {}
}

export class PostNameAndLogoSuccess {
  static readonly type = `${tag} post name and logo success`;
  constructor(public currentAppConfig: AppConfigApi) {}
}

export class PostNameAndLogoError {
  static readonly type = `${tag} post name and logo error`;
  constructor(public error: any) {}
}

export class FetchAppConfigRequest {
  static readonly type = `${tag} fetch app config request`;
  constructor(public appId: number) {}
}

export class FetchAppConfigSuccess {
  static readonly type = `${tag} fetch app config success`;
  constructor(public currentAppConfig: AppConfigApi) {}
}

export class FetchAppConfigError {
  static readonly type = `${tag} fetch app config error`;
}

export class PostAppConfigRequest {
  static readonly type = `${tag} post app config request`;
}

export class PostAppConfigSuccess {
  static readonly type = `${tag} post app config success`;
  constructor(public appConfig: AppConfigApi) {}
}

export class PostAppConfigError {
  static readonly type = `${tag} post app config error`;
  constructor(public error: string) {}
}

export class CheckAppNameAvailabilityRequest {
  static readonly type = `${tag} check app name validity request`;
  constructor(public name: string) {}
}

export class CheckAppNameAvailabilitySuccess {
  static readonly type = `${tag} check app name validity success`;
  constructor(public availability: boolean) {}
}

export class CheckAppNameAvailabilityError {
  static readonly type = `${tag} check app name validity error`;
}

export class UpdateAppConfig {
  static readonly type = `${tag} update app config`;
  constructor(
    public path: string[],
    public value: string | AppConfigTabs | number,
  ) {}
}

export class UpdateActiveSettingsCategory {
  static readonly type = `${tag} update active settings category`;
  constructor(public category: string) {}
}

export class UpdateActivePage {
  static readonly type = `${tag} update active page`;
  constructor(public page: number) {}
}

export class AddConfigAsset {
  static readonly type = `${tag} add config asset`;
  constructor(
    public name: string,
    public value: File | string,
    public imageUrl?: string,
  ) {}
}

export class RemoveImage {
  static readonly type = `${tag} remove image`;
  constructor(public name: string) {}
}

export class AddNewTab {
  static readonly type = `${tag} add new tab`;
  constructor(
    public newTab: TabModel,
    public index?: number,
  ) {}
}

export class DeleteTab {
  static readonly type = `${tag} delete tab`;
  constructor(public index: number) {}
}

export class SetBackgroundType {
  static readonly type = `${tag} set background type`;
  constructor(public type: string) {}
}

export class UpdatePlanRequest {
  static readonly type = `${tag} update plan request`;
  constructor(
    public planId: number,
    public billing: string,
  ) {}
}

export class UpdatePlanSuccess {
  static readonly type = `${tag} update plan success`;
}

export class UpdatePlanError {
  static readonly type = `${tag} update plan error`;
}

export class TogglePayingPublicationAddon {
  static readonly type = `${tag} toggle paying publication addon`;
  constructor(public publicationAddOn: boolean) {}
}

export class FetchUserRadiosRequest {
  static readonly type = `${tag} fetch user radios request`;
  constructor(public userId: number) {}
}

export class FetchUserRadiosSuccess {
  static readonly type = `${tag} fetch user radios success`;
  constructor(public apiResponse: UserRadio[]) {}
}

export class FetchUserRadiosError {
  static readonly type = `${tag} fetch user radios error`;
  constructor(public error: any) {}
}

export class FetchMountPointsRequest {
  static readonly type = `${tag} fetch mount points request`;
  constructor(public url: string) {}
}

export class FetchMountPointsSuccess {
  static readonly type = `${tag} fetch mount points success`;
  constructor(public apiResponse: string[]) {}
}

export class FetchMountPointError {
  static readonly type = `${tag} fetch mount points error`;
  constructor(public error: any) {}
}

export class AddNewStream {
  static readonly type = `${tag} add new stream`;
}

export class DeleteStream {
  static readonly type = `${tag} delete stream`;
  constructor(public index: number) {}
}

export class SetPageNameError {
  static readonly type = `${tag} set page name error`;
  constructor(public error: boolean) {}
}

export class ResetApiErrorMessage {
  static readonly type = `${tag} reset api error message`;
}

export class SetApiErrorMessage {
  static readonly type = `${tag} set api error message`;
  constructor(public error: string) {}
}

export class FinishAppCreationRequest {
  static readonly type = `${tag} finish creation`;
}

export class FinishAppCreationSuccess {
  static readonly type = `${tag} finish creation success`;
}

export class FinishAppCreationError {
  static readonly type = `${tag} finish creation error`;
  constructor(public error: any) {}
}
