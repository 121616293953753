import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { App } from '@app/core/models/app.model';
import { AppState } from '@app/core/states/app.state';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { AppConfigApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import {
  FetchMonetizationRequest,
  StoreMonetizationSetting,
} from '@app/features/app-creation-and-configuration/states/monetization.actions';
import {
  MonetizationSettings,
  MonetizationState,
} from '@app/features/app-creation-and-configuration/states/monetizations.state';
import { StepperState } from '@app/features/app-creation-and-configuration/states/stepper.state';
import { environment } from '@env/environment';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { Select, Store } from '@ngxs/store';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
} from 'rxjs';

@Component({
  selector: 'rk-admob',
  standalone: false,
  templateUrl: './admob.component.html',
  styleUrl: './admob.component.scss',
})
export class AdmobComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfig)
  currentAppConfig$: Observable<AppConfigApi>;

  @Select(AppState.currentApp)
  currentApp$: Observable<App>;

  @Select(AppConfigurationState.monetizationAvailable)
  monetizationAvailable$: Observable<boolean>;

  @Select(AppConfigurationState.availableOnIphone)
  availableOnIphone$: Observable<boolean>;

  @Select(MonetizationState.fetchedMonetizationSettings)
  fetchedMonetizationSettings$: Observable<MonetizationSettings>;

  @Select(StepperState.isCreationProcess)
  isCreationProcess$: Observable<boolean>;

  showAdProvider = false;
  activateInterstitialAndroid = false;
  activateBannerAndroid = false;
  activateInterstitialIos = false;
  activateBannerIos = false;
  admobAppId = new FormControl(null);
  admobInterstitialId = new FormControl(null);
  admobBannerId = new FormControl(null);
  admobAppIdIos = new FormControl(null);
  admobInterstitialIdIos = new FormControl(null);
  admobBannerIdIos = new FormControl(null);
  faAndroid = faAndroid;
  faApple = faApple;
  private readonly subscriptions = new Subscription();
  private serviceid = 0;
  private idapplications = 0;

  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        const appId = params['appId'];
        if (appId) {
          this.store.dispatch(new FetchMonetizationRequest(Number(appId)));
        }
      }),
    );

    // set global config
    this.subscriptions.add(
      this.currentAppConfig$.pipe(filter(config => !!config)).subscribe(config => {
        this.serviceid = config.serviceid;
        this.idapplications = config.idapplications;
      }),
    );
    // set monetization settings
    this.subscriptions.add(
      this.fetchedMonetizationSettings$
        .pipe(filter(settings => !!settings))
        .subscribe(settings => {
          this.admobAppId.setValue(settings.admobAppId);
          this.admobInterstitialId.setValue(settings.admobInterstitialId, {
            emitEvent: false,
          });
          this.admobBannerId.setValue(settings.admobBannerId);
          this.admobAppIdIos.setValue(settings.admobAppIdIos);
          this.admobInterstitialIdIos.setValue(settings.admobInterstitialIdIos, {
            emitEvent: false,
          });
          this.admobBannerIdIos.setValue(settings.admobBannerIdIos);
          this.showAdProvider = settings.pubOption === 'YES';
          this.activateInterstitialAndroid = settings.admobInterstitialId !== null;
          this.activateBannerAndroid = settings.admobBannerId !== null;
          this.activateInterstitialIos = settings.admobInterstitialIdIos !== null;
          this.activateBannerIos = settings.admobBannerIdIos !== null;
          if (this.showAdProvider) {
            this.store.dispatch(new StoreMonetizationSetting('pubOption', 'YES'));
            this.store.dispatch(
              new StoreMonetizationSetting('admobAppId', settings.admobAppId),
            );
            this.store.dispatch(
              new StoreMonetizationSetting(
                'admobInterstitialId',
                settings.admobInterstitialId,
              ),
            );
            this.store.dispatch(
              new StoreMonetizationSetting('admobBannerId', settings.admobBannerId),
            );
            this.store.dispatch(
              new StoreMonetizationSetting('admobAppIdIos', settings.admobAppIdIos),
            );
            this.store.dispatch(
              new StoreMonetizationSetting(
                'admobInterstitialIdIos',
                settings.admobInterstitialIdIos,
              ),
            );
            this.store.dispatch(
              new StoreMonetizationSetting('admobBannerIdIos', settings.admobBannerIdIos),
            );
          }
        }),
    );

    // react to app Id changes
    this.subscriptions.add(
      this.admobAppId.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(new StoreMonetizationSetting('admobAppId', value));
        }),
    );

    // react to interstitial Id changes
    this.subscriptions.add(
      this.admobInterstitialId.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(new StoreMonetizationSetting('admobInterstitialId', value));
        }),
    );

    // react to banner Id changes
    this.subscriptions.add(
      this.admobBannerId.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(new StoreMonetizationSetting('admobBannerId', value));
        }),
    );

    // react to app Id changes
    this.subscriptions.add(
      this.admobAppIdIos.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(new StoreMonetizationSetting('admobAppIdIos', value));
        }),
    );

    // react to interstitial Id changes
    this.subscriptions.add(
      this.admobInterstitialIdIos.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(
            new StoreMonetizationSetting('admobInterstitialIdIos', value),
          );
        }),
    );

    // react to banner Id changes
    this.subscriptions.add(
      this.admobBannerIdIos.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(new StoreMonetizationSetting('admobBannerIdIos', value));
        }),
    );
  }
  upgradeOffer(): void {
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonUpgradeOfferAdmob',
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));
    if (this.serviceid === undefined || this.serviceid === null || this.serviceid === 0) {
      window.location.href = `/plan/${this.idapplications}`;
    } else {
      window.location.href = `${environment.whmcs.url}/upgrade.php?type=package&id=${this.serviceid}`;
    }
  }

  onAdProviderToggle(showAdProvider: boolean) {
    this.showAdProvider = showAdProvider;
    if (showAdProvider) {
      this.store.dispatch(new StoreMonetizationSetting('pubOption', 'YES'));
      this.store.dispatch(
        new StoreMonetizationSetting('admobAppId', this.admobAppId.value),
      );
      this.store.dispatch(
        new StoreMonetizationSetting(
          'admobInterstitialId',
          this.admobInterstitialId.value,
        ),
      );
      this.store.dispatch(
        new StoreMonetizationSetting('admobBannerId', this.admobBannerId.value),
      );
      this.store.dispatch(
        new StoreMonetizationSetting('admobAppIdIos', this.admobAppIdIos.value),
      );
      this.store.dispatch(
        new StoreMonetizationSetting(
          'admobInterstitialIdIos',
          this.admobInterstitialIdIos.value,
        ),
      );
      this.store.dispatch(
        new StoreMonetizationSetting('admobBannerIdIos', this.admobBannerIdIos.value),
      );
    } else {
      this.store.dispatch(new StoreMonetizationSetting('pubOption', 'NO'));
    }
    const status = showAdProvider ? 'ON' : 'OFF';

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'SwitchAdsProvider',
        component: 'Switch',
        status: 'Status ' + status,
      }),
    );
    this.store.dispatch(new LogEvent('Switch Clicked'));
  }

  onAdTypeToggle(type: string, checked: boolean) {
    if (type === 'interstitialAndroid') {
      this.activateInterstitialAndroid = checked;
      if (checked) {
        this.store.dispatch(
          new StoreMonetizationSetting(
            'admobInterstitialId',
            this.admobInterstitialId.value,
          ),
        );
      } else {
        this.store.dispatch(new StoreMonetizationSetting('admobInterstitialId', null));
      }
      // event tracking
      this.store.dispatch(
        new SetEventProperties({
          name: 'SwitchAndroidInterstitialAdsProvider',
          component: 'Switch',
          status: 'Status ' + checked ? 'ON' : 'OFF',
        }),
      );
      this.store.dispatch(new LogEvent('Switch Clicked'));
    } else if (type === 'bannerAndroid') {
      this.activateBannerAndroid = checked;
      if (checked) {
        this.store.dispatch(
          new StoreMonetizationSetting('admobBannerId', this.admobBannerId.value),
        );
      } else {
        this.store.dispatch(new StoreMonetizationSetting('admobBannerId', null));
      }
      // event tracking
      this.store.dispatch(
        new SetEventProperties({
          name: 'SwitchAndroidBannerAdsProvider',
          component: 'Switch',
          status: 'Status ' + checked ? 'ON' : 'OFF',
        }),
      );
      this.store.dispatch(new LogEvent('Switch Clicked'));
    } else if (type === 'interstitialIos') {
      this.activateInterstitialIos = checked;
      if (checked) {
        this.store.dispatch(
          new StoreMonetizationSetting(
            'admobInterstitialIdIos',
            this.admobInterstitialIdIos.value,
          ),
        );
      } else {
        this.store.dispatch(new StoreMonetizationSetting('admobInterstitialIdIos', null));
      }
      // event tracking
      this.store.dispatch(
        new SetEventProperties({
          name: 'SwitchIosInterstitialAdsProvider',
          component: 'Switch',
          status: 'Status ' + checked ? 'ON' : 'OFF',
        }),
      );
      this.store.dispatch(new LogEvent('Switch Clicked'));
    } else if (type === 'bannerIos') {
      this.activateBannerIos = checked;
      if (checked) {
        this.store.dispatch(
          new StoreMonetizationSetting('admobBannerIdIos', this.admobBannerIdIos.value),
        );
      } else {
        this.store.dispatch(new StoreMonetizationSetting('admobBannerIdIos', null));
      }
      // event tracking
      this.store.dispatch(
        new SetEventProperties({
          name: 'SwitchIosBannerAdsProvider',
          component: 'Switch',
          status: 'Status ' + checked ? 'ON' : 'OFF',
        }),
      );
      this.store.dispatch(new LogEvent('Switch Clicked'));
    }
  }

  // event tracking
  clickEvent(event: string) {
    if(event === "admob") {
      this.store.dispatch(
        new SetEventProperties({
          name: 'LinkAdmobWebsite',
          component: 'Link',
        }),
      );
    } else if(event === "tutorial") {
      this.store.dispatch(
        new SetEventProperties({
          name: 'LinkAdmobTutorial',
          component: 'Link',
        }),
      );
    } else {
      return;
    }


    this.store.dispatch(new LogEvent('Link Clicked'));
  }

  inputEvent(name: string) {
    const isEmpty = name === "AdmobAppIdAndroid" && !this.admobAppId.value
      || name === "AdmobInterstitialIdAndroid" && !this.admobInterstitialId.value
      || name === "AdmobBannerIdAndroid" && !this.admobBannerId.value
      || name === "AdmobAppIdIos" && !this.admobAppIdIos.value
      || name === "AdmobInterstitialIdIos" && !this.admobInterstitialIdIos.value
      || name === "AdmobBannerIdIos" && !this.admobBannerIdIos.value;

    this.store.dispatch(
      new SetEventProperties({
        name: 'Input' + name,
        component: 'Input',
        is_empty: isEmpty,
      }),
    );

    this.store.dispatch(new LogEvent('Input Changed'));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
