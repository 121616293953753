import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppConfigApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { HtmlTabModel } from '@app/features/app-creation-and-configuration/models/app-tabs.model';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Editor, Toolbar } from 'ngx-editor';
import {
  Observable,
  Subscription,
  combineLatest,
  debounceTime,
  filter,
  map,
  take,
} from 'rxjs';

@Component({
  selector: 'rk-html-page-configuration',
  templateUrl: './html-page-configuration.component.html',
  styleUrl: './html-page-configuration.component.scss',
})
export class HtmlPageConfigurationComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.activePageIndex)
  activePageIndex$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfig)
  currentAppConfig$: Observable<AppConfigApi>;

  private readonly subscriptions = new Subscription();
  pageIndex: number;
  backgroundColor$: Observable<string>;
  htmlContent = new FormControl('');
  editor: Editor;
  isHtmlView = false;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  colorPresets = ['#252525', '#FF9150', '#FF4757', '#2ED573', '#1E90FF'];

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.editor = new Editor();

    this.subscriptions.add(
      this.activePageIndex$
        .pipe(filter(index => !!index))
        .subscribe(id => (this.pageIndex = Number(id))),
    );

    this.backgroundColor$ = this.currentAppConfig$.pipe(
      filter(config => !!config),
      take(1),
      map(config => {
        const htmlTab = config.onglets[this.pageIndex] as HtmlTabModel;

        return htmlTab.backgroundColor;
      }),
    );

    // set html content
    this.subscriptions.add(
      combineLatest([
        this.currentAppConfig$.pipe(
          filter(config => !!config),
          take(1),
        ),
        this.activePageIndex$,
      ]).subscribe(([config, index]) => {
        const htmlTab = config.onglets[index] as HtmlTabModel;
        this.htmlContent.setValue(htmlTab.codeHTML, { emitEvent: false });
      }),
    );

    // html content changes
    this.subscriptions.add(
      this.htmlContent.valueChanges.pipe(debounceTime(500)).subscribe(newValue => {
        this.store.dispatch(
          new UpdateAppConfig(
            ['onglets', this.pageIndex.toString(), 'codeHTML'],
            newValue,
          ),
        );
      }),
    );
  }

  onBackgroundColorChange(color: string) {
    this.store.dispatch(
      new UpdateAppConfig(
        ['onglets', this.pageIndex.toString(), 'backgroundColor'],
        color,
      ),
    );
  }

  toggleHtmlView(): void {
    this.isHtmlView = !this.isHtmlView;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.editor.destroy();
  }
}
