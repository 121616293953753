import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CoreModule } from '@app/core';
import { ResponsiveState } from '@app/core/states/responsive.state';
import { UiModule } from '@app/ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgxEditorModule } from 'ngx-editor';
import { AddNewPageComponent } from './components/app-configuration/add-new-page/add-new-page.component';
import { AlarmConfigurationComponent } from './components/app-configuration/alarm-configuration/alarm-configuration.component';
import { AppConfigurationShellComponent } from './components/app-configuration/app-configuration-shell/app-configuration-shell.component';
import { AudioDedicationsConfigurationComponent } from './components/app-configuration/audio-dedications-configuration/audio-dedications-configuration.component';
import { BackgroundColorSettingsComponent } from './components/app-configuration/background-color-settings/background-color-settings.component';
import { ButtonStyleSettingsComponent } from './components/app-configuration/button-style-settings/button-style-settings.component';
import { DivRadiusSettingsComponent } from './components/app-configuration/div-radius-settings/div-radius-settings.component';
import { DivShadowSettingsComponent } from './components/app-configuration/div-shadow-settings/div-shadow-settings.component';
import { FontFamilySelectorComponent } from './components/app-configuration/font-family-selector/font-family-selector.component';
import { FontSizeSelectorComponent } from './components/app-configuration/font-size-selector/font-size-selector.component';
import { GeneralConfigurationComponent } from './components/app-configuration/general-configuration/general-configuration.component';
import { HtmlPageConfigurationComponent } from './components/app-configuration/html-page-configuration/html-page-configuration.component';
import { IconStyleSelectorComponent } from './components/app-configuration/icon-style-selector/icon-style-selector.component';
import { MarginSettingsComponent } from './components/app-configuration/margin-settings/margin-settings.component';
import { MenuConfigurationShellComponent } from './components/app-configuration/menu-configuration/menu-configuration-shell/menu-configuration-shell.component';
import { MenuContentComponent } from './components/app-configuration/menu-configuration/menu-content/menu-content.component';
import { MenuDesignComponent } from './components/app-configuration/menu-configuration/menu-design/menu-design.component';
import { OtherPagesConfigurationComponent } from './components/app-configuration/other-pages-configuration/other-pages-configuration.component';
import { PagesListComponent } from './components/app-configuration/pages-list/pages-list.component';
import { AddNewStreamComponent } from './components/app-configuration/player-configuration/add-new-stream/add-new-stream.component';
import { PlayerButtonsComponent } from './components/app-configuration/player-configuration/player-buttons/player-buttons.component';
import { PlayerConfigurationShellComponent } from './components/app-configuration/player-configuration/player-configuration-shell/player-configuration-shell.component';
import { PlayerContentComponent } from './components/app-configuration/player-configuration/player-content/player-content.component';
import { PlayerDesignComponent } from './components/app-configuration/player-configuration/player-design/player-design.component';
import { StreamEditorComponent } from './components/app-configuration/player-configuration/stream-editor/stream-editor.component';
import { StreamListComponent } from './components/app-configuration/player-configuration/stream-list/stream-list.component';
import { SettingsCategoryListComponent } from './components/app-configuration/settings-category-list/settings-category-list.component';
import { SimulatorComponent } from './components/app-configuration/simulator/simulator.component';
import { TextAlignSettingsComponent } from './components/app-configuration/text-align-settings/text-align-settings.component';
import { CreateAppFormComponent } from './components/app-creation/create-app-form/create-app-form.component';
import { WelcomeAnimationComponent } from './components/app-creation/welcome-animation/welcome-animation.component';
import { AssetDisplayComponent } from './components/assets-management/asset-display/asset-display.component';
import { AssetDynamicMockComponent } from './components/assets-management/asset-display/asset-dynamic-mock/asset-dynamic-mock.component';
import { AssetsManagementComponent } from './components/assets-management/assets-management.component';
import { AdmobComponent } from './components/monetization/admob/admob.component';
import { MonetizationComponent } from './components/monetization/monetization.component';
import { OfferComponent } from './components/plan/offer/offer.component';
import { PlanComponent } from './components/plan/plan.component';
import { PublicationAddonDialogComponent } from './components/plan/publication-addon-dialog/publication-addon-dialog.component';
import { PublicationComponent } from './components/publication/publication.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { AppConfigurationState } from './states/app-configuration.state';
import { StepperState } from './states/stepper.state';

@NgModule({
  declarations: [
    SimulatorComponent,
    StepperComponent,
    CreateAppFormComponent,
    AssetsManagementComponent,
    PublicationComponent,
    PlanComponent,
    OfferComponent,
    WelcomeAnimationComponent,
    GeneralConfigurationComponent,
    AppConfigurationShellComponent,
    SettingsCategoryListComponent,
    PagesListComponent,
    AddNewPageComponent,
    MenuContentComponent,
    MenuConfigurationShellComponent,
    MenuDesignComponent,
    MonetizationComponent,
    AdmobComponent,
    PlayerConfigurationShellComponent,
    PlayerContentComponent,
    PlayerDesignComponent,
    StreamEditorComponent,
    StreamListComponent,
    AddNewStreamComponent,
    PlayerButtonsComponent,
    AlarmConfigurationComponent,
    AudioDedicationsConfigurationComponent,
    OtherPagesConfigurationComponent,
    HtmlPageConfigurationComponent,
    BackgroundColorSettingsComponent,
    IconStyleSelectorComponent,
    DivRadiusSettingsComponent,
    TextAlignSettingsComponent,
    ButtonStyleSettingsComponent,
    MarginSettingsComponent,
    DivShadowSettingsComponent,
    PublicationAddonDialogComponent,
    FontSizeSelectorComponent,
    FontFamilySelectorComponent,
    AssetDisplayComponent,
    AssetDynamicMockComponent,
  ],
  imports: [
    FontAwesomeModule,
    NgxEditorModule,
    TranslateModule,
    UiModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonToggleModule,
    CoreModule,
    DragDropModule,
    NgOptimizedImage,
    FormsModule,
    CommonModule,
    MatProgressSpinnerModule,
    NgxsModule.forFeature([StepperState, AppConfigurationState, ResponsiveState]),
  ],
})
export class AppConfigurationModule {}
