<div class="relative h-full">
  @if (isLoading) {
    <div class="absolute inset-0 flex justify-center items-center bg-white">
      <mat-progress-spinner
        mode="indeterminate"
        [strokeWidth]="5"
        [diameter]="50"
      ></mat-progress-spinner>
    </div>
  }
  <div class="p-8 container" >
    <h1 class="font-bold text-2xl mb-4">{{ titleMap[data.key] | translate }}</h1>
    <div class="text-sm mb-8">
      <div class="font-bold">{{ 'image-cropper.restrictions' | translate }}</div>
      <div>
        {{ 'image-cropper.recommended' | translate }} {{ data.width }} x {{ data.height }}
      </div>
    </div>
    <div class="crop-container" [class.ready]="!isLoading">
      <div class="img-container">
        <img #image [src]="imageUrl" crossorigin="anonymous" />
      </div>
    </div>
    <div class="flex text-sm mt-6 gap-8">
      <div class="flex-1">
        <div class="flex justify-between">
          <span> {{ 'image-cropper.zoom' | translate }} </span>
          <span> {{ zoom * 100 | number: '1.0-0' }}%</span>
        </div>
        <mat-slider class="w-full" [min]="minZoom" [max]="maxZoom" [step]="0.05">
          <input matSliderThumb [(ngModel)]="zoom" (ngModelChange)="setZoom($event)" />
        </mat-slider>
      </div>
      <div class="flex-1">
        <div class="flex justify-between">
          <span> {{ 'image-cropper.rotation' | translate }} </span>
          <span> {{ rotation }}°</span>
        </div>
        <mat-slider class="w-full" min="0" max="360" step="45">
          <input matSliderThumb [(ngModel)]="rotation" (ngModelChange)="rotate($event)" />
        </mat-slider>
      </div>
    </div>
    <div class="mt-8 flex justify-between">
      <rk-button
        [variant]="'border-grey'"
        (buttonClick)="onReplace()"
        [text]="'image-cropper.replace' | translate"
      >
      </rk-button>
      <div class="flex gap-4">
        <rk-button
          [variant]="'secondary'"
          (buttonClick)="onCancel()"
          [text]="'image-cropper.cancel' | translate"
        >
        </rk-button>
        <rk-button (buttonClick)="onSave()" [text]="'image-cropper.save' | translate">
        </rk-button>
      </div>
    </div>
  </div>
</div>
