<div class="h-full flex flex-col bg-white relative">
  <rk-stepper
    [customNextStep]="finishCreation"
    [customNextStepText]="'monetization.next-step' | translate"
    [nextStepIsDisabled]="(isCreationProcess$ | async) && nextStepIsDisabled"
  ></rk-stepper>
  <div class="h-full overflow-scroll">
    <div class="w-full flex flex-col items-center">
      <h1 class="w-full text-center text-4xl mt-13 mb-10 font-bold">
        {{ 'monetization.title' | translate }}
      </h1>
      <rk-infobox type="info">{{ 'monetization.configure-later' | translate }}</rk-infobox>
      <!-- prettier-ignore -->
      <rk-admob></rk-admob>
    </div>
  </div>
  <div
    *ngIf="isFinishingPublication$ | async"
    class="absolute top-0 flex justify-center items-center w-full h-full backdrop-blur z-20"
  >
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="7"
      [diameter]="100"
    ></mat-progress-spinner>
  </div>
</div>
