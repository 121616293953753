import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from './../environments/environment';
import { ActiveGuard } from './core/auth/active.guard';
import { AuthGuard } from './core/auth/auth.guard';
import { DraftGuard } from './core/auth/draft.guard';
import { PaidGuard } from './core/auth/paid.guard';
import { AdminDashboardComponent } from './features/admin/components/admin-dashboard/admin-dashboard.component';
import { AppConfigurationShellComponent } from './features/app-creation-and-configuration/components/app-configuration/app-configuration-shell/app-configuration-shell.component';
import { CreateAppFormComponent } from './features/app-creation-and-configuration/components/app-creation/create-app-form/create-app-form.component';
import { AssetsManagementComponent } from './features/app-creation-and-configuration/components/assets-management/assets-management.component';
import { PlanComponent } from './features/app-creation-and-configuration/components/plan/plan.component';
import { PublicationComponent } from './features/app-creation-and-configuration/components/publication/publication.component';
import { LoginComponent } from './features/login/login.component';
import { NotificationDashboardComponent } from './features/notifications/components/notification-dashboard/notification-dashboard.component';
import { StatisticsDashboardComponent } from './features/statistics/components/statistics-dashboard/statistics-dashboard.component';
import { MonetizationComponent } from './features/app-creation-and-configuration/components/monetization/monetization.component';

const loginRoute = environment.auth.isLocalLoginEnabled
  ? [
      {
        path: 'login',
        component: LoginComponent,
      },
    ]
  : [];

const routes: Routes = [
  ...loginRoute,
  {
    path: 'apps',
    canActivate: [AuthGuard],
    component: StatisticsDashboardComponent,
    data: {
      showAppsList: true,
      showMainMenu: true,
      showMainContainerPadding: true,
      fetchUserApps: true,
    },
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    component: NotificationDashboardComponent,
    data: {
      showAppsList: true,
      showMainMenu: true,
      showMainContainerPadding: true,
      fetchUserApps: true,
    },
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    component: AdminDashboardComponent,
    data: {
      showAppsList: true,
      showMainMenu: true,
      showMainContainerPadding: true,
      fetchUserApps: true,
    },
  },
  {
    path: 'create',
    canActivate: [AuthGuard],
    component: CreateAppFormComponent,
    data: {
      isCreationProcess: true,
    },
  },
  {
    path: 'create/:appId',
    canActivate: [AuthGuard, DraftGuard],
    component: CreateAppFormComponent,
    data: {
      isCreationProcess: true,
    },
  },
  {
    path: 'configure/:appId',
    canActivate: [AuthGuard, DraftGuard],
    component: AppConfigurationShellComponent,
    data: {
      isCreationProcess: true,
    },
  },
  {
    path: 'simulator/:appId',
    canActivate: [AuthGuard, ActiveGuard],
    component: AppConfigurationShellComponent,
    data: {
      showAppsList: true,
      showReturnToDashboardLink: true,
      fetchUserApps: true,
    },
  },
  {
    path: 'plan/:appId',
    canActivate: [AuthGuard, PaidGuard],
    component: PlanComponent,
    data: {
      isCreationProcess: true,
    },
  },
  {
    path: 'assets/:appId',
    canActivate: [AuthGuard, DraftGuard],
    component: AssetsManagementComponent,
    data: {
      isCreationProcess: true,
    },
  },
  {
    path: 'monetization/:appId',
    canActivate: [AuthGuard, DraftGuard],
    component: MonetizationComponent,
    data: {
      isCreationProcess: true,
    },
  },
  {
    path: 'update/:appId',
    canActivate: [AuthGuard, ActiveGuard],
    component: AssetsManagementComponent,
    data: {
      showAppsList: true,
      showReturnToDashboardLink: true,
      fetchUserApps: true,
    },
  },
  {
    path: 'publication/:appId',
    canActivate: [AuthGuard],
    component: PublicationComponent,
    data: {
      isCreationProcess: true,
    },
  },
  { path: '', redirectTo: '/apps', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
