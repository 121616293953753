<rk-page-configuration-container>
  <div content>
    <rk-setting-divider></rk-setting-divider>
    @if (type === 'rss') {
      <div class="p-6">
        <rk-form-field>
          <div class="opacity-70">{{ 'other-config.rss-link' | translate }}</div>
          <input
            class="mt-2 mb-3"
            [(ngModel)]="rssLink"
            (input)="onRssLinkChanged($event)"
        /></rk-form-field>
        <rk-infobox type="info">
          <div [innerHTML]="'other-config.what-is-rss' | translate"></div>
        </rk-infobox>
      </div>
    }
    @if (type === 'dedicaces' || type === 'livefeed') {
      <div class="p-6">
        <rk-form-field>
          <div class="opacity-70">{{ 'other-config.rk-website-link' | translate }}</div>
          <input
            class="mt-2 mb-3"
            [(ngModel)]="rkRadioSiteUrl"
            (input)="onRkRadioSiteUrlChanged($event)"
        /></rk-form-field>
        <rk-infobox type="warning">
          {{ 'other-config.need-a-rk-website' | translate }}
          <a [href]="createWebsiteLink$ | async" target="_blank" class="font-bold">
            {{ 'other-config.rk-website-cta' | translate }}
          </a>
        </rk-infobox>
      </div>
    }
    @if (type === 'site') {
      <div class="p-6">
        <rk-form-field>
          <div class="opacity-70">{{ 'other-config.website-link' | translate }}</div>
          <input
            class="mt-2 mb-3"
            [(ngModel)]="websiteUrl"
            (input)="onWebsiteUrlChanged($event)"
        /></rk-form-field>
      </div>
    }
  </div>
</rk-page-configuration-container>
