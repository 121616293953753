import { Injectable } from '@angular/core';
import { ASSETS_DIMENSIONS } from '@app/core/constants/constants';

interface ImageDimension {
  width: number;
  height: number;
  name: string;
}

export type ImageSource = 'rawSplashscreen' | 'rawLogo';

@Injectable({
  providedIn: 'root',
})
export class ImageResizingService {
  async resizeImages(
    file: File,
    source: ImageSource,
    planId: number,
  ): Promise<Map<string, Blob>> {
    const resizedImages = new Map<string, Blob>();
    const dimensions = ASSETS_DIMENSIONS.filter(
      dimension => dimension.source === source,
    ).filter(dimension => dimension.plan.includes(planId));

    if (!dimensions) {
      throw new Error(`Invalid context: ${source}`);
    }

    try {
      const img = await this.createImage(file);

      const resizePromises = dimensions.map(dimension =>
        this.processResize(img, dimension).then(blob => {
          resizedImages.set(dimension.name, blob);
        }),
      );

      await Promise.all(resizePromises);
      URL.revokeObjectURL(img.src);

      return resizedImages;
    } catch (error) {
      throw new Error(`Failed to process images: ${error}`);
    }
  }

  private createImage(file: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => resolve(img);
      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        reject(new Error('Failed to load image'));
      };
    });
  }

  private processResize(
    img: HTMLImageElement,
    dimension: ImageDimension,
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      try {
        const canvas = document.createElement('canvas');
        canvas.width = dimension.width;
        canvas.height = dimension.height;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          throw new Error('Could not get canvas context');
        }

        const scale = Math.max(
          dimension.width / img.width,
          dimension.height / img.height,
        );

        const scaledWidth = img.width * scale;
        const scaledHeight = img.height * scale;

        const x = (dimension.width - scaledWidth) / 2;
        const y = (dimension.height - scaledHeight) / 2;

        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, dimension.width, dimension.height);
        ctx.drawImage(img, x, y, scaledWidth, scaledHeight);

        canvas.toBlob(
          blob => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Failed to convert canvas to blob'));
            }
          },
          'image/jpeg',
          0.95,
        );
      } catch (error) {
        reject(error);
      }
    });
  }
}
