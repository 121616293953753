<div
  [class.hide-after-content]="hideAfterContent"
  class="w-full notification-sidebar-container md:w-[301px] md:ml-8 rounded-xl"
  [@fadeInOut]
>
  <div #scrollableContent class="scrollable-content p-6" (scroll)="onScroll($event)">
    <div class="flex justify-between items-center mb-6">
      <!-- Title -->
      <h1 class="">
        {{ 'notification-dashboard.title' | translate }}
      </h1>
      <!-- Create notification button -->
      <rk-button
        [icon]="'plus'"
        [variant]="'secondary'"
        [iconOnly]="true"
        (buttonClick)="openForm()"
      ></rk-button>
    </div>
    <!-- List -->
    <ul class="flex w-full flex-1" *ngFor="let notification of notifications$ | async">
      <!-- List item -->
      <li [class.waiting]="notification.waiting" class="mb-3 p-6">
        <div class="flex text-xs" *ngIf="!notification.waiting">
          <fa-icon [icon]="'clock'" class="opacity-30"></fa-icon>
          <!-- Date -->
          <span class="opacity-50 ml-1">
            {{ formatDate(notification.sendAt) }}
          </span>
          <!-- Total sent -->
          <fa-icon [icon]="'paper-plane'" class="opacity-30 ml-2"></fa-icon>
          <span class="opacity-50 ml-1">{{ getTotalSent(notification) || '-' }}</span>
        </div>
        <!-- Scheduled / Draft tag -->
        <div *ngIf="notification.waiting">
          <div class="scheduled-draft-tag px-2 py-1 text-white w-fit flex items-center">
            <fa-icon
              class="mr-1 text-[10px]"
              [icon]="notification.enabled ? 'calendar-alt' : 'pen'"
            ></fa-icon>
            <span class="text-xs leading-none">
              {{
                notification.enabled
                  ? ('notification-sidebar.scheduled' | translate)
                  : ('notification-table.draft' | translate)
              }}
            </span>
          </div>
        </div>
        <!-- Message -->
        <div class="notification-message text-base font-medium">
          {{ notification.message }}
        </div>
      </li>
    </ul>
  </div>
</div>
