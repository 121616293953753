import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { fadeInOutAnimation } from '@app/core/animations';
import { ASSETS_DIMENSIONS } from '@app/core/constants/constants';
import { SetCurrentApp } from '@app/core/states/app.actions';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import {
  CropperState,
  ImageCropperComponent,
} from '@app/ui/components/image-cropper/image-cropper.component';
import { Selectedimage } from '@app/ui/components/image-upload/image-upload.component';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  Subscription,
} from 'rxjs';
import { ImageResizingService, ImageSource } from '../../services/image-resizing.service';
import { FinishAppCreationRequest } from '../../states/app-configuration.actions';
import { AppConfigurationState } from '../../states/app-configuration.state';
import {
  FetchAssetsRequest,
  PostImageAssetsRequest,
  SaveCropperState,
  SetRawImage,
  StoreAppName,
  StoreAsset,
} from '../../states/assets.actions';
import { AssetKey, AssetsState, constructAssetUrl } from '../../states/assets.state';
import { SkipToStep } from '../../states/stepper.actions';
import { StepperState } from '../../states/stepper.state';

@Component({
  selector: 'rk-assets-management',
  templateUrl: './assets-management.component.html',
  styleUrls: ['./assets-management.component.scss'],
  animations: [fadeInOutAnimation],
})
export class AssetsManagementComponent implements OnInit, OnDestroy {
  isProcessing = false;
  private readonly subscriptions: Subscription = new Subscription();
  @Select(AssetsState.smartphoneSplashscreenURL) smartphoneURL$: Observable<string>;
  @Select(AssetsState.smartphoneLargeSplashscreenURL)
  smartphoneLargeURL$: Observable<string>;
  @Select(AssetsState.tvSplashscreenURL) tvSplashscreenURL$: Observable<string>;
  @Select(AssetsState.topShelfTvURL) topShelfTvURL$: Observable<string>;
  @Select(AssetsState.iconTvURL) iconTvURL$: Observable<string>;
  @Select(AssetsState.logoURL) logoURL$: Observable<string>;
  @Select(AssetsState.rawImages) rawImages$: Observable<{ [key: string]: File }>;
  @Select(AssetsState.isLoading) isLoading$: Observable<boolean>;
  @Select(AppConfigurationState.isFinishingPublication)
  isFinishingPublication$: Observable<boolean>;
  @Select(StepperState.isCreationProcess) isCreationProcess$: Observable<boolean>;
  @Select(AppConfigurationState.availableOnAndroidTV)
  availableOnAndroidTV$: Observable<boolean>;
  @Select(AppConfigurationState.availableOnAppleTV)
  availableOnAppleTV$: Observable<boolean>;
  @Select(AssetsState.fetchedAssets)
  fetchedAssets$: Observable<{ [key: string]: string }>;
  @Select(AppConfigurationState.currentAppIdPlan) planId$: Observable<number>;
  nextStepIsDisabled$: Observable<boolean>;
  @Select(AppConfigurationState.currentAppConfigName)
  currentAppConfigName$: Observable<string>;
  appName = new FormControl(null);
  isCreationProcess: boolean;

  constructor(
    private readonly imageProcessor: ImageResizingService,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.store.dispatch(new SkipToStep(2));

    // get route parameters
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        const appId = params['appId'];
        if (appId) {
          this.fetchAssets(Number(appId));
          this.setCurrentAppId(Number(appId));
        }
      }),
    );

    // set property for better template readabilty
    this.subscriptions.add(
      this.isCreationProcess$.subscribe(isCreationProcess => {
        this.isCreationProcess = isCreationProcess;
      }),
    );

    // set the app name
    this.subscriptions.add(
      this.currentAppConfigName$
        .pipe(filter(config => !!config))
        .subscribe(name => this.appName.setValue(name)),
    );

    // react to app name changes
    this.subscriptions.add(
      this.appName.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(new StoreAppName(value));
        }),
    );

    this.nextStepIsDisabled$ = combineLatest([
      this.fetchedAssets$,
      this.planId$,
      this.isLoading$,
    ]).pipe(
      filter(([fetchedAssets, planId]) => !!fetchedAssets && !!planId),
      map(([fetchedAssets, planId, isLoading]) => {
        const requiredAssets = ASSETS_DIMENSIONS.filter(
          dim => dim.plan.includes(planId) && dim.isRequired,
        ).map(dim => dim.name);
        const isMissingAsset = requiredAssets.some(asset => !fetchedAssets[asset]);

        return isMissingAsset || isLoading;
      }),
    );

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'PageSimulatorAssets',
        component: 'Page',
      }),
    );
    this.store.dispatch(new LogEvent('Page Viewed'));
  }

  async onRawImageSelected(source: ImageSource, selectedImage: Selectedimage) {
    const planId = this.store.selectSnapshot(AppConfigurationState.currentAppIdPlan);
    if (!selectedImage) {
      return;
    }
    this.setRawImage(source, selectedImage.file);
    this.resetRawImages(source);
    this.isProcessing = true;

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: `Upload-${source}`,
        component: 'ImageUpload',
      }),
    );
    this.store.dispatch(new LogEvent('Source Image Uploaded'));

    try {
      const resizedImages = await this.imageProcessor.resizeImages(
        selectedImage.file,
        source,
        planId,
      );
      resizedImages.forEach((blob, name) => {
        const imageFile = new File([blob], name, { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(imageFile);
        this.storeAsset(name, imageFile, imageUrl);
      });
    } catch (error) {
      console.error('Error processing images:', error);
    } finally {
      this.postImageAssets();
      this.isProcessing = false;
    }
  }

  async openCropper(key: AssetKey) {
    const rawImages = this.store.selectSnapshot(AssetsState.rawImages);
    const assets = this.store.selectSnapshot(AssetsState.fetchedAssets);

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: `Crop-${key}`,
        component: 'Cropper',
      }),
    );
    this.store.dispatch(new LogEvent('Cropper Opened'));

    // determine the source image
    let originalFile: File | null = null;
    let sourceImageUrl: string | null = null;
    let savedState: CropperState = null;

    if (rawImages) {
      // handle logo/iconTV assets
      if (key === 'logo' || key === 'iconTv') {
        originalFile =
          rawImages[key] || (rawImages['rawLogo'] ? rawImages['rawLogo'] : null);
      } else {
        // handle splashscreen assets
        originalFile =
          rawImages[key] ||
          (rawImages['rawSplashscreen'] ? rawImages['rawSplashscreen'] : null);
      }
    }
    // set the source image URL
    if (originalFile) {
      sourceImageUrl = URL.createObjectURL(originalFile);
      savedState = this.store.selectSnapshot(AssetsState.cropperStates)?.[key];
    } else if (assets[key]) {
      sourceImageUrl = constructAssetUrl(assets[key]);
    } else {
      console.error(`No source image found for key: ${key}`);

      return;
    }
    const width = ASSETS_DIMENSIONS.find(dimension => dimension.name === key)?.width;
    const height = ASSETS_DIMENSIONS.find(dimension => dimension.name === key)?.height;
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      data: {
        sourceImageUrl,
        aspectRatio: width / height,
        width,
        height,
        key,
        savedState,
      },
      disableClose: true,
      panelClass: 'cropper-dialog',
      maxWidth: '95vw',
    });

    const result = await dialogRef.afterClosed().toPromise();

    if (result) {
      const file = new File([result], key, { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(file);
      this.storeAsset(key, file, imageUrl);
      this.postImageAssets();
    }
  }

  async replaceAsset(key: string) {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png,.jpg,.jpeg';

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: `Replace-${key}`,
        component: 'ImageUpload',
      }),
    );
    this.store.dispatch(new LogEvent('Image Replaced'));

    input.onchange = async (event: any) => {
      const file = event.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      if (!file) {
        return;
      }
      this.setRawImage(key, file);
      this.storeAsset(key, file, imageUrl);
      this.saveCropperState(key, null);
      this.postImageAssets();
    };

    input.click();
  }

  private resetRawImages(source: ImageSource) {
    if (source === 'rawSplashscreen') {
      this.setRawImage('splashscreen', null);
      this.setRawImage('splashscreenLarge', null);
      this.setRawImage('splashscreenTv', null);
      this.setRawImage('topShelfTv', null);
    } else {
      this.setRawImage('logo', null);
      this.setRawImage('iconTV', null);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @Dispatch()
  storeAsset(key: string, file: File, url: string) {
    return new StoreAsset(key, file, url);
  }

  @Dispatch()
  fetchAssets(appId: number) {
    return new FetchAssetsRequest(appId);
  }

  @Dispatch()
  setCurrentAppId(appId: number) {
    return new SetCurrentApp(appId);
  }

  @Dispatch()
  postImageAssets() {
    return new PostImageAssetsRequest();
  }

  @Dispatch()
  setRawImage(key: string, image: File) {
    return new SetRawImage(key, image);
  }

  @Dispatch()
  finishAppCreation() {
    return new FinishAppCreationRequest();
  }

  @Dispatch()
  saveCropperState(key: string, data: CropperState) {
    return new SaveCropperState(key, data);
  }
}
