import { AppConfigApi } from '../models/app-config.model';
import { MonetizationSettingKey } from './monetizations.state';

const tag = '[Monetization]';

export class FetchMonetizationRequest {
  static readonly type = `${tag} fetch monetization request`;
  constructor(public appId: number) {}
}

export class FetchMonetizationSuccess {
  static readonly type = `${tag} fetch monetization success`;
  constructor(public currentAppConfig: AppConfigApi) {}
}

export class FetchMonetizationError {
  static readonly type = `${tag} fetch monetization error`;
  constructor(public error: any) {}
}

export class StoreMonetizationSetting {
  static readonly type = `${tag} add monetization setting`;
  constructor(
    public key: MonetizationSettingKey,
    public value: string,
  ) {}
}
