import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthState } from '@app/core/auth/states/auth.state';
import { AppConfigurationService } from '@app/features/app-creation-and-configuration/services/app-configuration.service';
import {
  UpdateActivePage,
  UpdateActiveSettingsCategory,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { environment } from '@env/environment';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import {
  AppConfigApi,
  updateRadioTabFluxFromStreamApis,
} from '../../../models/app-config.model';
import { AppConfigurationState } from '../../../states/app-configuration.state';

@Component({
  selector: 'rk-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss'],
})
export class SimulatorComponent implements AfterViewInit, OnDestroy, OnInit {
  @Select(AppConfigurationState.currentAppConfig)
  currentAppConfig$: Observable<AppConfigApi>;

  @Select(AppConfigurationState.activePageIndex)
  activePageIndex$: Observable<number>;

  @Select(AppConfigurationState.showImageLoadingModal)
  showImageLoadingModal$: Observable<boolean>;

  @Select(AuthState.getAccessToken)
  token$: Observable<string>;

  @ViewChild('rnwIframe', { static: false })
  iframe!: ElementRef<HTMLIFrameElement>;

  mobileAppSimulatorUrl: any;
  isIframeLoading = true;

  private appConfig: AppConfigApi | null = null;
  private token: string | null = null;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly sanitizer: DomSanitizer,
    private readonly appConfigurationService: AppConfigurationService,
  ) {
    this.subscriptions.add(
      this.appConfigurationService.onOpenMenu.subscribe(() => {
        this.iframe.nativeElement.contentWindow.postMessage({ openMenu: true }, '*');
      }),
    );
  }

  ngOnInit() {
    // set react app URL
    this.mobileAppSimulatorUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.urls.SIMULATOR}`,
    );

    // udate app config
    this.subscriptions.add(
      this.currentAppConfig$
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(config => {
          // converts stream array for dynamic display
          this.appConfig = updateRadioTabFluxFromStreamApis(config);
          this.trySendConfigToIframe();
        }),
    );

    // sets page index
    this.subscriptions.add(
      this.activePageIndex$.subscribe(index => {
        if (index !== null) {
          this.iframe?.nativeElement.contentWindow.postMessage({ navigate: index }, '*');
        }
      }),
    );

    // sets token
    this.subscriptions.add(
      this.token$.subscribe(token => {
        if (token !== undefined) {
          this.token = token;
        }
      }),
    );
  }

  ngAfterViewInit() {
    window.addEventListener('message', this.handleMessage);
    this.iframe.nativeElement.addEventListener('load', this.onIframeLoad);
  }

  onIframeLoad = () => {
    this.isIframeLoading = false;
  };

  handleMessage = (event: MessageEvent) => {
    if (event.data.type === 'app-ready') {
      this.trySendConfigToIframe();
    }
    if (event.data.type === 'tab' && event.data.index !== null) {
      this.store.dispatch(new UpdateActiveSettingsCategory('pages'));
      this.store.dispatch(new UpdateActivePage(parseInt(event.data.index, 10)));
    }
  };

  trySendConfigToIframe() {
    if (this.appConfig && this.iframe && this.iframe.nativeElement.contentWindow) {
      this.iframe?.nativeElement.contentWindow.postMessage(
        { config: this.appConfig },
        '*',
      );
      this.iframe?.nativeElement.contentWindow.postMessage(
        { bearerToken: this.token },
        '*',
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    window.removeEventListener('message', this.handleMessage);
    this.iframe.nativeElement.removeEventListener('load', this.onIframeLoad);
  }
}
