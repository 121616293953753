import { Component, Input } from '@angular/core';

@Component({
  selector: 'rk-asset-dynamic-mock',
  templateUrl: './asset-dynamic-mock.component.html',
  styleUrl: './asset-dynamic-mock.component.scss',
})
export class AssetDynamicMockComponent {
  @Input() assetMainColor = '#000000';
  @Input() assetKey: string;
}
