import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SetCurrentApp } from '@app/core/states/app.actions';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { AppConfigApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import {
  FetchAppConfigRequest,
  FinishAppCreationRequest,
  TogglePayingPublicationAddon,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { SkipToStep } from '@app/features/app-creation-and-configuration/states/stepper.actions';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { StepperState } from '../../states/stepper.state';
import { PublicationAddonDialogComponent } from '../plan/publication-addon-dialog/publication-addon-dialog.component';

@Component({
  selector: 'rk-monetization',
  templateUrl: './monetization.component.html',
  styleUrls: ['./monetization.component.scss'],
})
export class MonetizationComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfig)
  currentAppConfig$: Observable<AppConfigApi>;

  @Select(StepperState.isCreationProcess)
  isCreationProcess$: Observable<boolean>;

  @Select(AppConfigurationState.monetizationAvailable)
  monetizationAvailable$: Observable<boolean>;

  @Select(AppConfigurationState.isFinishingPublication)
  isFinishingPublication$: Observable<boolean>;

  @Select(AppConfigurationState.currentAppIdPlan)
  currentAppIdPlan$: Observable<number>;

  nextStepIsDisabled = false;

  private readonly subscriptions: Subscription = new Subscription();

  private planId: number;

  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.store.dispatch(new SkipToStep(3));

    // fetch config
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        const appId = params['appId'];
        if (appId) {
          this.store.dispatch(new FetchAppConfigRequest(appId));
          this.store.dispatch(new SetCurrentApp(Number(appId)));
        }
      }),
    );
    this.subscriptions.add(
      this.currentAppIdPlan$.subscribe(id => {
        this.planId = id;
      }),
    );
    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'PageSimulatorMonetization',
        component: 'Page',
      }),
    );
    this.store.dispatch(new LogEvent('Page Viewed'));
  }

  finishCreation = (): void => {
    const planId = this.store.selectSnapshot(AppConfigurationState.currentAppIdPlan);
    if (planId !== 6) {
      // prevent scrolling when the dialog is opened
      document.body.classList.add('disable-scroll');
      const dialogRef = this.dialog.open(PublicationAddonDialogComponent, {});
      dialogRef.afterClosed().subscribe(result => {
        if (result === undefined) {
          document.body.classList.remove('disable-scroll');

          return;
        } else {
          if (result === true) {
            this.store.dispatch(new TogglePayingPublicationAddon(true));
          } else {
            this.store.dispatch(new TogglePayingPublicationAddon(false));
          }
          this.store.dispatch(new FinishAppCreationRequest());
        }
      });
    } else {
      this.store.dispatch(new FinishAppCreationRequest());
    }
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
