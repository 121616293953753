<div class="grid">
  <div
    [class]="
      'pointer-events-auto flex z-10 col-start-1 row-start-1 justify-center ' +
      ((isCreationProcess$ | async) ? 'mt-13' : 'mt-8')
    "
    *ngIf="(monetizationAvailable$ | async) === false"
  >
    <div class="no-monetization-container flex flex-col text-center items-center">
      {{ 'monetization.change-offer-text' | translate }}
      <rk-button class="mt-6" [text]="'upgrade-offer.button' | translate" (buttonClick)="upgradeOffer()"></rk-button>
      <div class="flex flex-row">
        <div class="flex">
          <img src="/assets/interstitial.svg" class="pointer-events-none" />
        </div>
        <div class="flex mt-32">
          <img src="/assets/banner.svg" class="pointer-events-none" />
        </div>
      </div>
    </div>
  </div>

  <div
    [class]="
      'flex flex-col items-center w-full z-0 col-start-1 row-start-1 ' +
      ((isCreationProcess$ | async) ? 'mt-13' : 'mt-8')
    "
    [class.blur]="(monetizationAvailable$ | async) === false"
  >
    <div class="w-[1000px]">
      <div class="flex justify-between items-center">
        <div class="font-bold">
          {{ 'monetization.option-toggle' | translate }}
        </div>
        <rk-switch
          [isChecked]="showAdProvider"
          (toggle)="onAdProviderToggle($event)"
        ></rk-switch>
      </div>
      <div class="mt-2">{{ 'monetization.description-1' | translate }}</div>
      <div>
        {{ 'monetization.description-2' | translate }} (<a target="_blank" href="https://admob.google.com/" class="underline" (click)="clickEvent('admob')">admob.google.com</a>) {{ 'monetization.and' | translate }} <a target="_blank" [href]="'monetization.admob-tutorial-url' | translate" class="underline" (click)="clickEvent('tutorial')">{{ 'monetization.our-tutorial' | translate }}</a>.
      </div>
      <div class="grid  mt-9" [class.opacity-50]="!showAdProvider" [class.grid-cols-2]="(availableOnIphone$ | async)">
        <div [class.left-column]="(availableOnIphone$ | async)">
          <rk-form-field class="mb-8">
            <div class="flex mb-1">
              <fa-icon [icon]="faAndroid"></fa-icon>
              <div class="font-bold mb-1 ml-2">{{ 'monetization.android-app-id' | translate }}</div>
            </div>
            <input type="text" [formControl]="admobAppId" [readOnly]="!showAdProvider" (blur)="inputEvent('AdmobAppIdAndroid')" placeholder="ca-app-pub-3940256099942544~3347511713"/>
          </rk-form-field>

          <rk-form-field class="mb-8 flex flex-row" >
            <div class="flex flex-col mr-3" [class.opacity-50]="!activateInterstitialAndroid" [class.w-full]="(availableOnIphone$ | async) === false">
              <div class="flex flex-row" [class.justify-between]="(availableOnIphone$ | async) === false">
                <div class="flex flex-col">
                  <div class="font-bold mb-1">{{ 'monetization.interstitial' | translate }} - {{ 'monetization.ad-unit-id' | translate }}</div>
                  <div class="opacity-70 mb-1">{{ 'monetization.interstitial-description' | translate }}</div>
                </div>

                <rk-switch
                  [isChecked]="activateInterstitialAndroid"
                  (toggle)="onAdTypeToggle('interstitialAndroid', $event)"
                  [isDisabled]="!showAdProvider"
                ></rk-switch>
              </div>

              <input type="text" [formControl]="admobInterstitialId" [readOnly]="!showAdProvider || !activateInterstitialAndroid" (blur)="inputEvent('AdmobInterstitialIdAndroid')" placeholder="ca-app-pub-3940256099942544/1033173712"/>
            </div>
            <div
              class="rounded border admob-preview-style-container m-1 "
              [class.admob-preview-style-selected]="activateInterstitialAndroid"
            >
              <div class="flex justify-around items-center admob-preview-selector">
                <img src="/assets/interstitial.svg" />
              </div>
            </div>
          </rk-form-field>

          <rk-form-field class="mb-8 flex flex-row" >
            <div class="flex flex-col mr-3" [class.opacity-50]="!activateBannerAndroid" [class.w-full]="(availableOnIphone$ | async) === false">
              <div class="flex flex-row" [class.justify-between]="(availableOnIphone$ | async) === false">
                <div class="flex flex-col">
                  <div class="font-bold mb-1">{{ 'monetization.banner' | translate }} - {{ 'monetization.ad-unit-id' | translate }}</div>
                  <div class="opacity-70 mb-1">{{ 'monetization.banner-description' | translate }}</div>
                </div>

                <rk-switch
                  [isChecked]="activateBannerAndroid"
                  (toggle)="onAdTypeToggle('bannerAndroid', $event)"
                  [isDisabled]="!showAdProvider"
                ></rk-switch>
              </div>

              <input type="text" [formControl]="admobBannerId" [readOnly]="!showAdProvider || !activateBannerAndroid" (blur)="inputEvent('AdmobBannerIdAndroid')" placeholder="ca-app-pub-3940256099942544/9214589741"/>
            </div>
            <div
              class="rounded border admob-preview-style-container m-1 "
              [class.admob-preview-style-selected]="activateBannerAndroid"
            >
              <div class="flex justify-around items-center admob-preview-selector">
                <img src="/assets/banner.svg" />
              </div>
            </div>
          </rk-form-field>
        </div>
        <div class="right-column" *ngIf="(availableOnIphone$ | async)">
          <rk-form-field class="mb-8">
            <div class="flex mb-1">
              <fa-icon [icon]="faApple"></fa-icon>
              <div class="font-bold mb-1 ml-2">{{ 'monetization.ios-app-id' | translate }}</div>
            </div>
            <input type="text" [formControl]="admobAppIdIos" [readOnly]="!showAdProvider"(blur)="inputEvent('AdmobAppIdIos')" placeholder="ca-app-pub-3940256099942544~3347511713" />
          </rk-form-field>

          <rk-form-field class="mb-8 flex flex-row" >
            <div class="flex flex-col mr-3" [class.opacity-50]="!activateInterstitialIos">
              <div class="flex flex-row">
                <div class="flex flex-col">
                  <div class="font-bold mb-1">{{ 'monetization.interstitial' | translate }} - {{ 'monetization.ad-unit-id' | translate }}</div>
                  <div class="opacity-70 mb-1">{{ 'monetization.interstitial-description' | translate }}</div>
                </div>

                <rk-switch
                  [isChecked]="activateInterstitialIos"
                  (toggle)="onAdTypeToggle('interstitialIos', $event)"
                  [isDisabled]="!showAdProvider"
                ></rk-switch>
              </div>

              <input type="text" [formControl]="admobInterstitialIdIos" [readOnly]="!showAdProvider || !activateInterstitialIos" (blur)="inputEvent('AdmobInterstitialIdIos')" placeholder="ca-app-pub-3940256099942544/1033173712" />
            </div>
            <div
              class="rounded border admob-preview-style-container m-1 "
              [class.admob-preview-style-selected]="activateInterstitialIos"
            >
              <div class="flex justify-around items-center admob-preview-selector">
                <img src="/assets/interstitial.svg" />
              </div>
            </div>
          </rk-form-field>

          <rk-form-field class="mb-8 flex flex-row" >
            <div class="flex flex-col mr-3" [class.opacity-50]="!activateBannerIos">
              <div class="flex flex-row">
                <div class="flex flex-col">
                  <div class="font-bold mb-1">{{ 'monetization.banner' | translate }} - {{ 'monetization.ad-unit-id' | translate }}</div>
                  <div class="opacity-70 mb-1">{{ 'monetization.banner-description' | translate }}</div>
                </div>

                <rk-switch
                  [isChecked]="activateBannerIos"
                  (toggle)="onAdTypeToggle('bannerIos', $event)"
                  [isDisabled]="!showAdProvider"
                ></rk-switch>
              </div>

              <input type="text" [formControl]="admobBannerIdIos" [readOnly]="!showAdProvider || !activateBannerIos" (blur)="inputEvent('AdmobBannerIdIos')" placeholder="ca-app-pub-3940256099942544/9214589741"/>
            </div>
            <div
              class="rounded border admob-preview-style-container m-1 "
              [class.admob-preview-style-selected]="activateBannerIos"
            >
              <div class="flex justify-around items-center admob-preview-selector">
                <img src="/assets/banner.svg" />
              </div>
            </div>
          </rk-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
